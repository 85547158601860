import { Node, NodeTypes } from "@xyflow/react";
import useModal from "antd/es/modal/useModal";
import {
  Type_ClickhouseInput,
  Type_Extrapolation,
  Type_Filter,
  Type_FlatFile,
  Type_GroupBy,
  Type_Join,
  Type_KnowledgeSpaceClassInput,
  Type_KnowledgeSpaceClassOutput,
  Type_KnowledgeSpaceDictionaryInput,
  Type_LaunchFlow,
  Type_Mapping,
  Type_Note,
  Type_PlanXInput,
  Type_PlanXOutput,
  Type_PostgresInput,
  Type_PostgresOutput,
  Type_RabbitOutput,
  Type_RowGen,
  Type_TargetFlatFile,
} from "../blocks";
import { useICState } from "../state";
import { Context, Footer, Header, SContainer } from "./components";
import ClickhouseInput from "./inputs/clickhouseinput";
import FlatFileWindow from "./inputs/flatFile";
import KnowledgeSpaceClassInput from "./inputs/knowledgeSpaceClassInput";
import KnowledgeSpaceDictionaryInput from "./inputs/knowledgeSpaceDictionaryInput";
import PlanXFigureInputWindow from "./inputs/planxFigureInput";
import PostgresInput from "./inputs/postgreinput";
import KnowledgeSpaceClassOutput from "./targets/knowledgeSpaceClassOutput";
import LaunchFlow from "./targets/launchFlow";
import PlanXFigureOutputWindow from "./targets/planxFigureOutput";
import PostgresOutput from "./targets/postgreoutput";
import RabbitmqOutput from "./targets/rabbitmqOutput";
import TargetFlatFileWindow from "./targets/targetFlatFile";
import ExtrapolationWindow from "./transformation/extrapolation";
import FilterWindow from "./transformation/filter";
import GroupByWindow from "./transformation/groupBy";
import JoinWindow from "./transformation/join";
import MappingWindow from "./transformation/mapping";
import RowGenWindow from "./transformation/rowGen";

export const nodeTypes: NodeTypes = {
  targetFlatFile: Type_TargetFlatFile,
  flatFile: Type_FlatFile,
  mapping: Type_Mapping,
  filter: Type_Filter,
  join: Type_Join,
  groupBy: Type_GroupBy,
  rowGen: Type_RowGen,
  knowledgeSpaceDictionaryInput: Type_KnowledgeSpaceDictionaryInput,
  // knowledgeSpaceDictionaryOutput: Type_KnowledgeSpaceDictionaryOutput,
  knowledgeSpaceClassInput: Type_KnowledgeSpaceClassInput,
  knowledgeSpaceClassOutput: Type_KnowledgeSpaceClassOutput,
  planxFigureInput: Type_PlanXInput,
  planxFigureOutput: Type_PlanXOutput,
  postgresInput: Type_PostgresInput,
  postgresOutput: Type_PostgresOutput,
  clickhouseInput: Type_ClickhouseInput,
  rabbitmqOutput: Type_RabbitOutput,
  launchFlow: Type_LaunchFlow,
  extrapolation: Type_Extrapolation,
  note: Type_Note,
};

export enum NodeType {
  target__flatFile,
  flatfile,
  mapping,
  join,
  filter,
  group,
  rowGen,
  knowledgeSpaceClassInput,
  knowledgeSpaceClassOutput,
  knowledgeSpaceDictionaryInput,
  knowledgeSpaceDictionaryOutput,
  planxFigureInput,
  planxFigureOutput,
  postgresInput,
  postgresOutput,
  clickhouseInput,
  rabbitmqOutput,
  launchFlow,
  extrapolation,
  note,
}

const CONTENTS = {
  [NodeType.flatfile]: {
    Content: FlatFileWindow,
    title: "ПЛОСКИЙ ФАЙЛ",
    colors: {
      primary: "var(--Secondary-Green-400)",
      secondary: "var(--Secondary-Green-005)",
    },
  },
  [NodeType.mapping]: {
    Content: MappingWindow,
    title: "ВЫБОР",
    colors: {
      primary: "var(--Primary-400)",
      secondary: "var(--Primary-005)",
    },
  },
  [NodeType.filter]: {
    Content: FilterWindow,
    title: "ФИЛЬТР",
    colors: {
      primary: "var(--Secondary-Maroon-400)",
      secondary: "var(--Secondary-Maroon-005)",
    },
  },
  [NodeType.group]: {
    Content: GroupByWindow,
    title: "ГРУППИРОВКА",
    colors: {
      primary: "var(--Secondary-Teal-400)",
      secondary: "var(--Secondary-Teal-005)",
    },
  },
  [NodeType.join]: {
    Content: JoinWindow,
    title: "СОЕДИНЕНИЕ",
    colors: {
      primary: "var(--Secondary-Blue-400)",
      secondary: "var(--Secondary-Blue-005)",
    },
  },
  [NodeType.rowGen]: {
    Content: RowGenWindow,
    title: "TODO",
    colors: {
      primary: "var(--Secondary-Red-400)",
      secondary: "var(--Secondary-Red-005)",
    },
  },
  [NodeType.target__flatFile]: {
    Content: TargetFlatFileWindow,
    title: "ПЛОСКИЙ ФАЙЛ",
    colors: {
      primary: "var(--Secondary-Green-400)",
      secondary: "var(--Secondary-Green-005)",
    },
  },
  [NodeType.knowledgeSpaceClassInput]: {
    Content: KnowledgeSpaceClassInput,
    title: "KS КЛАСС ВХОД",
    colors: {
      primary: "var(--Secondary-Purple-400)",
      secondary: "var(--Secondary-Purple-005)",
    },
  },
  [NodeType.knowledgeSpaceClassOutput]: {
    Content: KnowledgeSpaceClassOutput,
    title: "KS КЛАСС ВЫХОД",
    colors: {
      primary: "var(--Secondary-Purple-400)",
      secondary: "var(--Secondary-Purple-005)",
    },
  },
  [NodeType.knowledgeSpaceDictionaryInput]: {
    Content: KnowledgeSpaceDictionaryInput,
    title: "KS СЛОВАРЬ ВХОД",
    colors: {
      primary: "var(--Secondary-Purple-400)",
      secondary: "var(--Secondary-Purple-005)",
    },
  },
  [NodeType.planxFigureInput]: {
    Content: PlanXFigureInputWindow,
    title: "PX ВХОД",
    colors: {
      primary: "var(--Secondary-Red-400)",
      secondary: "var(--Secondary-Red-005)",
    },
  },
  [NodeType.planxFigureOutput]: {
    Content: PlanXFigureOutputWindow,
    title: "PX ВЫХОД",
    colors: {
      primary: "var(--Secondary-Red-400)",
      secondary: "var(--Secondary-Red-005)",
    },
  },
  [NodeType.postgresInput]: {
    Content: PostgresInput,
    title: "POSTGRESQL ВХОД",
    colors: {
      primary: "var(--Secondary-Blue-400)",
      secondary: "var(--Secondary-Blue-005)",
    },
  },
  [NodeType.postgresOutput]: {
    Content: PostgresOutput,
    title: "POSTGRESQL ВЫХОД",
    colors: {
      primary: "var(--Secondary-Blue-400)",
      secondary: "var(--Secondary-Blue-005)",
    },
  },
  [NodeType.clickhouseInput]: {
    Content: ClickhouseInput,
    title: "CLICKHOUSE",
    colors: {
      primary: "var(--Secondary-Orange-400)",
      secondary: "var(--Secondary-Orange-005)",
    },
  },
  [NodeType.rabbitmqOutput]: {
    Content: RabbitmqOutput,
    title: "RABBITMQ",
    colors: {
      primary: "var(--Secondary-Orange-400)",
      secondary: "var(--Secondary-Orange-005)",
    },
  },
  [NodeType.launchFlow]: {
    Content: LaunchFlow,
    title: "LAUNCHER",
    colors: {
      primary: "var(--Primary-Grey-400)",
      secondary: "var(--Primary-Grey-005)",
    },
  },
  [NodeType.extrapolation]: {
    Content: ExtrapolationWindow,
    title: "ПРОГНОЗ",
    colors: {
      primary: "var(--Secondary-Maroon-400)",
      secondary: "var(--Secondary-Maroon-005)",
    },
  },
};

export const NodeWindow = (
  nodeType: NodeType | null,
  nodeId: string,
  nodes: Node[],
) => {
  const [modal, contextHolder] = useModal();

  const { setOpen } = useICState();

  if (nodeType === null || nodeType === undefined) {
    return null;
  }

  // @ts-ignore
  const { title, colors, Content } = CONTENTS[nodeType];

  if (!Content) {
    return null;
  }

  const actions = {
    close: async () => {
      const confirmed = await modal.confirm({
        zIndex: 10000,
        title: "Изменения не будут сохранены!",
        content: "Вы действительно хотите закрыть окно?",
        okText: "Да",
        cancelText: "Нет",
      });

      if (confirmed) {
        setOpen(false);
      }
    },
    save: () => {
      console.error("action.save is not implemented");
    },
  };

  const value = {
    title,
    colors,
    actions,
    node: nodes.find((node) => node.id === nodeId),
  };

  return (
    <Context.Provider value={value}>
      {contextHolder}
      <SContainer>
        <Header />
        <Content id={nodeId} />
        <Footer />
      </SContainer>
    </Context.Provider>
  );
};
