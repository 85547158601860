import {
  Button,
  ConfigProvider,
  Form,
  Input,
  Select,
  Tour,
  TourProps,
  notification,
} from "antd";
import { Checkbox } from "antd/lib";
import { useForm } from "antd/lib/form/Form";
import ruRU from "antd/lib/locale/ru_RU";
import { MRT_ColumnDef } from "material-react-table";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import LevelService from "../../../entities/model/LevelService";
import ModelService from "../../../entities/model/ModelService";
import { attributeDictionary } from "../../../entities/types/IDictionary";
import { LevelCreate } from "../../../entities/types/ILevel";
import { attributeLevel } from "../../../entities/types/IModel";
import { SmallTable } from "../../../shared/components/table";
import { fuzzyIsIn } from "../../../shared/helper/comparison";

const LevelCreatePage: React.FC<{
  model: string;
  modalState: any;
  dataFetch: any;
}> = ({ model, modalState, dataFetch }) => {
  const [state, setState] = useState<attributeDictionary[]>([]);
  const [attributesState, setAttributesState] = useState<attributeLevel[]>([]);
  const [timeLevels, setTimeLevels] = useState<
    { label: string; value: string }[]
  >([]);
  const [open, setOpen] = useState<boolean>(false);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);

  const navigate = useNavigate();

  const [form] = useForm();

  const fetchAttributes = async () => {
    const rawData = await ModelService.availableLevelAttributes(model);

    const data = rawData.data;
    setState(data);
    setAttributesState(data);
  };

  useEffect(() => {
    fetchAttributes();
  }, []);

  const fetchTime = async () => {
    const response = await ModelService.availableTimeLevels(model);
    if (response.code === 1) {
      setTimeLevels([
        { value: 0, label: "Нет" },
        ...response.data.map((el: any) => {
          switch (el.time) {
            case "DAY":
              return { value: 1, label: "День" };
            case "TWEEK":
              return { value: 2, label: "Техническая неделя" };
            case "CWEEK":
              return { value: 3, label: "Календарная неделя" };
            case "MONTH":
              return { value: 4, label: "Месяц" };
            case "QUARTER":
              return { value: 5, label: "Квартал" };
            case "YEAR":
              return { value: 6, label: "Год" };
            case "NONE":
              return { value: 0, label: "Нет" };
          }
        }),
      ]);
    }
  };

  useEffect(() => {
    fetchTime();
  }, []);

  const getCheckboxState = (row: any, key: keyof attributeLevel): boolean => {
    const values = attributesState.filter(
      (value) => value.id === row.original.id,
    );

    if (values.length === 0) {
      throw new Error(`Checkbox with id=${row.original.id} was not found.`);
    }

    if (values.length > 1) {
      throw new Error(
        `There are ${values.length} checkboxes with id=${row.original.id}.`,
      );
    }

    return values[0][key] === true;
  };

  const setCheckboxState = (
    row: any,
    event: any,
    key: keyof attributeLevel,
  ) => {
    setAttributesState(
      attributesState.map((value) => {
        if (value.id === row.original.id)
          return { ...value, [key]: event.target.checked };
        return value;
      }),
    );
  };

  const columns: MRT_ColumnDef<any>[] = [
    {
      header: "ID",
      accessorKey: "id",
    },
    {
      header: "Наименование",
      accessorKey: "name",
    },
    {
      header: "Выбран",
      accessorKey: "selected",
      Cell: ({ row }) => (
        <Checkbox
          checked={getCheckboxState(row, "selected")}
          onChange={(e) => setCheckboxState(row, e, "selected")}
        />
      ),
    },
    {
      header: "Ключ",
      accessorKey: "key",
      Cell: ({ row }) => {
        return (
          <Checkbox
            checked={getCheckboxState(row, "key")}
            onChange={(e) => setCheckboxState(row, e, "key")}
          />
        );
      },
    },
  ];

  const onFinish = async (e: any) => {
    const attributes: attributeLevel[] = attributesState.filter(
      (e: any) => e.selected,
    );
    if (attributes.length === 0) {
      return notification.error({
        message: "Необходимо выбрать хотя бы один атрибут для создания уровня",
      });
    }

    for (const attribute of attributes) {
      if (!attribute.key) attribute.key = false;
    }

    const toSend = new LevelCreate({
      model,
      id: e.id.toUpperCase(),
      name: e.name,
      description: e.description,
      period: e.period,
      attributes: attributes,
    });

    const response = await LevelService.create(toSend);

    notification.info({
      message: response.text,
      description: `Код ответа: ${response.code}`,
    });
    if (response.code === 1303) {
      modalState(false);
      dataFetch();

      // reset input fields
      form.resetFields();
      setAttributesState((state) =>
        state.map((value) => ({ ...value, selected: false, key: false })),
      );
    }
  };

  const steps: TourProps["steps"] = [
    {
      title: "Введите идентификатор уровня",
      description: "Уровень может содержать только латинские буквы и цифры.",
      target: () => ref1.current,
    },
    {
      title: "Введите наименование уровня",
      description:
        "Это короткое и понятное название (например, Продукт | Клиент | Месяц.",
      target: () => ref2.current,
    },
    {
      title: "Выберите период уровня",
      description: "", // Добавить описание
      target: () => ref3.current,
    },
    {
      title: "Перетащите в правую сторону необходимые атрибуты.",
      target: () => ref4.current,
    },
    {
      title:
        "После того как атрибуты были выбраны, расставьте их в порядке иерархичности",
      description:
        "Уровень 1 - самый детальный уровень. Атрибуты выше данного уровня считаются иерархичными более низкому уровню.", // Изменить описание
      target: () => ref5.current,
    },
    {
      title: "Не забудьте сохранить изменения!",
      target: () => ref6.current,
    },
  ];

  return (
    <div style={{ padding: 30 }}>
      <ConfigProvider locale={ruRU}>
        <Tour
          open={open}
          onClose={() => setOpen(false)}
          mask={false}
          type="primary"
          steps={steps}
        />
      </ConfigProvider>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "15px",
          alignItems: "center",
        }}
      >
        <div className="PageTitle">Создание уровня</div>
        <Button
          onClick={() => setOpen(true)}
          type="primary"
          style={{ margin: 0 }}
        >
          🎓 Инструкция
        </Button>
      </div>
      <Form
        name="basic"
        style={{
          width: "750px",
          display: "flex",
          flexDirection: "column",
          marginTop: "30px",
        }}
        initialValues={{ remember: true }}
        onFinish={(e) => onFinish(e)}
        autoComplete="off"
        layout="vertical"
        form={form}
      >
        <Form.Item
          name="id"
          rules={[
            { required: true, message: "Пожалуйста, введите идентификатор" },
            {
              pattern: /^[A-Z][A-Z0-9]*$/,
              message:
                "Идентификатор должен начинаться с заглавной буквы A-Z и содержать только A-Z и 0-9.",
            },
          ]}
          style={{ width: "50%" }}
        >
          <div
            ref={ref1}
            style={{ display: "flex", flexDirection: "column", gap: "15px" }}
          >
            <span style={{ fontSize: 18, fontWeight: 700 }}>
              <span style={{ color: "#37AB49", fontWeight: 900 }}>Шаг 1.</span>{" "}
              Введите идентификатор уровня
            </span>
            <Input size="large" placeholder="Идентификатор уровня" />
          </div>
        </Form.Item>
        <Form.Item
          name="name"
          rules={[
            { required: true, message: "Пожалуйста, введите наименование" },
          ]}
          style={{ width: "50%" }}
        >
          <div
            ref={ref2}
            style={{ display: "flex", flexDirection: "column", gap: "15px" }}
          >
            <span style={{ fontSize: 18, fontWeight: 700 }}>
              <span style={{ color: "#37AB49", fontWeight: 900 }}>Шаг 2.</span>{" "}
              Введите наименование уровня
            </span>
            <Input size="large" placeholder="Наименование уровня" />
          </div>
        </Form.Item>
        <Form.Item name="description" style={{ width: "50%" }}>
          <div
            ref={ref2}
            style={{ display: "flex", flexDirection: "column", gap: "15px" }}
          >
            <span style={{ fontSize: 18, fontWeight: 700 }}>
              <span style={{ color: "#37AB49", fontWeight: 900 }}>Шаг 3.</span>{" "}
              Введите описание уровня
            </span>
            <Input size="large" placeholder="Описание уровня" />
          </div>
        </Form.Item>
        <Form.Item style={{ width: "50%" }} name={"period"}>
          <div
            ref={ref3}
            style={{ display: "flex", flexDirection: "column", gap: "15px" }}
          >
            <span style={{ fontSize: 18, fontWeight: 700 }}>
              <span style={{ color: "#37AB49", fontWeight: 900 }}>Шаг 4.</span>{" "}
              Выберите временную гранулярность
            </span>
            <Select
              placeholder={"Период уровня"}
              options={timeLevels}
              onChange={(e: any) => form.setFieldValue("period", e)}
              showSearch={true}
              filterOption={(
                input,
                option?: { label: string; value: string },
              ) => fuzzyIsIn(input, option?.label ?? "")}
            />
          </div>
        </Form.Item>
        <Form.Item>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "15px" }}
          >
            <span style={{ fontSize: 18, fontWeight: 700 }}>
              <span style={{ color: "#37AB49", fontWeight: 900 }}>Шаг 5.</span>{" "}
              Выберите атрибуты и отметьте из них ключевые
            </span>
            <SmallTable columns={columns} data={state} />
          </div>
        </Form.Item>
        <Form.Item>
          <div ref={ref6}>
            <Button
              type="primary"
              htmlType="submit"
              style={{ backgroundColor: "#37AB49", width: "750px" }}
            >
              ➕ Создать
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default LevelCreatePage;
