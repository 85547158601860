import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Node } from "@xyflow/react";
import { Select, Switch, notification } from "antd";
import Empty from "antd/lib/empty";
import { produce } from "immer";
import { useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import KSService from "../../../../../entities/model/KSService";
import { fuzzyIsIn } from "../../../../../shared/helper/comparison";
import { getCyrillic } from "../../../../../shared/helper/cyrillic";
import { useICState } from "../../state";
import { Context, SContent } from "../components";
import { MappingField } from "../utils";

type Option = {
  label: string;
  value: string;
};

type Props = {
  id: string;
};

type State = {
  system: string;
  project: string;
  model: string;
  class: string;
  dataset: string;
  timeDependent: boolean;
  formula: MappingField[];
};

const initialState: State = {
  system: "",
  project: "",
  model: "",
  class: "",
  dataset: "",
  timeDependent: false,
  formula: [],
};

const KnowledgeSpaceClassInput: React.FC<Props> = ({ id }) => {
  const { nodes, setNodes } = useICState();
  const { actions } = useContext(Context);

  const [state, setState] = useState<State>(initialState);

  useEffect(() => {
    actions.save = () => {
      setNodes(
        produce((nodes: Node[]) => {
          const node = nodes.find((node) => node.id === id);
          node.data.system = state.system;
          node.data.project = state.project;
          node.data.model = state.model;
          node.data.class = state.class;
          node.data.dataset = state.dataset;
          node.data.timeDependent = state.timeDependent;
          node.data.formula = state.formula;
        }),
      );
    };
  });

  useEffect(() => {
    const node = nodes.find((node) => node.id === id);

    if (node) {
      setState(
        produce((state) => {
          state.system = node.data.system;
          state.project = node.data.project;
          state.model = node.data.model;
          state.class = node.data.class;
          state.dataset = node.data.dataset;
          state.timeDependent = node.data.timeDependent === true;
          state.formula = node.data.formula;
        }),
      );
    }

    KSgetSystemList();
  }, [id]);

  const fields = state.formula;
  const ksSystemSelected = state.system;
  const ksProjectSelected = state.project;
  const ksModelSelected = state.model;
  const ksClassSelected = state.class;
  const ksDatasetSelected = state.dataset;
  const ksTimeDependentSelected = state.timeDependent;

  const setFormula = (value: MappingField[]) => {
    setState(
      produce((state) => {
        state.formula = value;
      }),
    );
  };

  const setKSSystemSelected = (value: string) => {
    setState(
      produce((state) => {
        state.system = value;
      }),
    );
  };

  const setKSProjectSelected = (value: string) => {
    setState(
      produce((state) => {
        state.project = value;
      }),
    );
  };

  const setKSClassSelected = (value: string) => {
    setState(
      produce((state) => {
        state.class = value;
      }),
    );
  };

  const setKSModelSelected = (value: string) => {
    setState(
      produce((state) => {
        state.model = value;
      }),
    );
  };

  const setKSDatasetSelected = (value: string) => {
    setState(
      produce((state) => {
        state.dataset = value;
      }),
    );
  };

  const setKSTimeDependentSelected = (value: boolean) => {
    setState(
      produce((state) => {
        state.timeDependent = value;
      }),
    );
  };

  const [ksSystemOptions, setKSSystemOptions] = useState<Option[]>([]);
  const [ksProjectOptions, setKSProjectOptions] = useState<Option[]>([]);
  const [ksClassOptions, setKSClassOptions] = useState<Option[]>([]);
  const [ksModelOptions, setKSModelOptions] = useState<Option[]>([]);
  const [ksDatasetOptions, setKSDatasetOptions] = useState<Option[]>([]);

  const KSgetSystemList = async () => {
    const getSystems = await KSService.getAll();
    setKSSystemOptions(
      getSystems.data.map((el: any) => ({ label: el.id, value: el.id })),
    );
  };

  const KSgetProjectList = async () => {
    if (ksSystemSelected && ksSystemSelected !== "") {
      const getProjects = await KSService.getProjects(ksSystemSelected);
      if (getProjects.code === 1) {
        setKSProjectOptions((prevOptions: any) =>
          getProjects.data.map((el: any) => ({
            label: el.name,
            value: el.uuid,
          })),
        );
      } else {
        notification.error({
          message: getProjects.text,
          description: `Код ответа: ${getProjects.code}`,
        });
      }
    }
  };

  useEffect(() => {
    KSgetProjectList();
  }, [ksSystemSelected]);

  const KSgetClassList = async () => {
    const getClasses = await KSService.getClasses(
      ksSystemSelected,
      ksProjectSelected,
    );
    if (getClasses.code === 1) {
      setKSClassOptions((prevOptions: any) =>
        getClasses.data.map((el: any) => ({
          label: el.name,
          value: el.uuid,
        })),
      );
    }
  };

  useEffect(() => {
    KSgetClassList();
  }, [ksProjectSelected]);

  const KSgetModelList = async () => {
    const getModels = await KSService.getModels(
      ksSystemSelected,
      ksProjectSelected,
    );
    if (getModels.code === 1) {
      setKSModelOptions((prevOptions: any) =>
        getModels.data.map((el: any) => ({
          label: el.name,
          value: el.uuid,
        })),
      );
    }
  };

  useEffect(() => {
    KSgetModelList();
  }, [ksProjectSelected]);

  const KSgetFigures = async () => {
    const getFigures = await KSService.getFigures(
      ksSystemSelected,
      ksProjectSelected,
      ksClassSelected,
    );

    if (getFigures.code === 1) {
      const newFields = getFigures.data
        .filter((el: any) => el.canBeTimed === ksTimeDependentSelected)
        .map((el: any) => ({
          uuid: el.uuid,
          id: getCyrillic(el.name.replace(/[^A-Za-zА-Яа-яёЁ0-9]/g, "")),
          desc: el.name,
          type:
            el.dataType === "number"
              ? "DECIMAL"
              : el.dataType === "datetime"
                ? "DATE"
                : el.dataType === "boolean"
                  ? "BOOL"
                  : "STRING",
        }));

      if (ksTimeDependentSelected) {
        setFormula([
          {
            uuid: uuidv4(),
            id: "_Date",
            type: "DATE",
            desc: "Дата для показателей со временем",
          },
          {
            uuid: uuidv4(),
            id: "_Object",
            type: "STRING",
            desc: "Наименование объекта",
          },
          ...newFields,
        ]);
      } else {
        setFormula([
          {
            uuid: uuidv4(),
            id: "_Object",
            type: "STRING",
            desc: "Наименование объекта",
          },
          ...newFields,
        ]);
      }
    }
  };

  const KSgetDataset = async () => {
    if (
      ksSystemSelected &&
      ksSystemSelected !== "" &&
      ksProjectSelected &&
      ksProjectSelected !== "" &&
      ksModelSelected &&
      ksModelSelected !== ""
    ) {
      const getDatasets = await KSService.getDatasets(
        ksSystemSelected,
        ksProjectSelected,
        ksModelSelected,
      );
      console.log(getDatasets);
      if (getDatasets.code === 1) {
        setKSDatasetOptions((prevOptions: any) =>
          getDatasets.data.map((el: any) => ({
            label: el.name,
            value: el.uuid,
          })),
        );
      }
    }
  };

  useEffect(() => {
    KSgetDataset();
  }, [ksModelSelected]);

  useEffect(() => {
    KSgetFigures();
  }, [ksClassSelected, ksTimeDependentSelected]);

  return (
    <SContent>
      <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>
            Подключение KS
          </div>
          <Select
            value={ksSystemSelected}
            style={{ width: "300px", color: "black" }}
            onChange={(el) => setKSSystemSelected(el)}
            options={ksSystemOptions}
            showSearch={true}
            filterOption={(input, option?: { label: string; value: string }) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>Проект KS</div>
          <Select
            value={ksProjectSelected}
            style={{ width: "300px", color: "black" }}
            onChange={(el) => setKSProjectSelected(el)}
            options={ksProjectOptions}
            showSearch={true}
            filterOption={(input, option?: { label: string; value: string }) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>Модель KS</div>
          <Select
            value={ksModelSelected}
            style={{ width: "300px", color: "black" }}
            onChange={(el) => setKSModelSelected(el)}
            options={ksModelOptions}
            showSearch={true}
            filterOption={(input, option?: { label: string; value: string }) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>Датасет KS</div>
          <Select
            value={ksDatasetSelected}
            style={{ width: "300px", color: "black" }}
            onChange={(el) => setKSDatasetSelected(el)}
            options={ksDatasetOptions}
            showSearch={true}
            filterOption={(input, option?: { label: string; value: string }) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>Класс KS</div>
          <Select
            value={ksClassSelected}
            style={{ width: "300px", color: "black" }}
            onChange={(el) => setKSClassSelected(el)}
            options={ksClassOptions}
            showSearch={true}
            filterOption={(input, option?: { label: string; value: string }) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>
            Времязависимые
          </div>
          <Switch
            checked={ksTimeDependentSelected}
            onChange={(el) => setKSTimeDependentSelected(el)}
          />
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>
                Идентификатор
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Описание</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Тип</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fields?.length ? (
              fields.map((el: MappingField, ind: number) => (
                <TableRow key={ind}>
                  <TableCell>{el.id}</TableCell>
                  <TableCell>{el.desc}</TableCell>
                  <TableCell>{el.type}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={100}>
                  <Empty
                    imageStyle={{ height: "50px" }}
                    description="Нет исходящих полей"
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </SContent>
  );
};

export default KnowledgeSpaceClassInput;
