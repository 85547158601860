import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Node } from "@xyflow/react";
import { Button, Input, Popover, Select, Switch, notification } from "antd";
import Empty from "antd/lib/empty";
import { produce } from "immer";
import * as R from "ramda";
import { useContext, useEffect, useState } from "react";
import KSService from "../../../../../entities/model/KSService";
import { fuzzyIsIn } from "../../../../../shared/helper/comparison";
import { getCyrillic } from "../../../../../shared/helper/cyrillic";
import { useICState } from "../../state";
import { Context, SContent } from "../components";
import { MappingField } from "../utils";
import IntegrationManager from "../utils/common";
import IntegrationFieldsManager from "../utils/fields";

const OBJECT_UUID = "4dbee879-18a5-443a-a76b-691fcef36708";
const DATE_UUID = "ded4dcc5-27f2-45b5-9726-6ce078193e23";

type Option = {
  label: string;
  value: string;
};

type Props = {
  id: string;
};
type State = {
  system: string;
  project: string;
  model: string;
  class: string;
  dataset: string;
  timeDependent: boolean;
  folder: string;
  formula: MappingField[];
};

const initialState: State = {
  system: "",
  project: "",
  model: "",
  class: "",
  dataset: "",
  timeDependent: false,
  folder: "",
  formula: [],
};

const KnowledgeSpaceClassOutput: React.FC<Props> = ({ id }) => {
  const { nodes, edges, setNodes } = useICState();
  const { actions } = useContext(Context);

  const [state, setState] = useState<State>(initialState);

  useEffect(() => {
    actions.save = () => {
      setNodes(
        produce((nodes: Node[]) => {
          const node = nodes.find((node) => node.id === id);
          node.data.system = state.system;
          node.data.project = state.project;
          node.data.model = state.model;
          node.data.class = state.class;
          node.data.dataset = state.dataset;
          node.data.timeDependent = state.timeDependent;
          node.data.folder = state.folder;
          node.data.formula = state.formula;
        }),
      );
    };
  });

  const [inFs, setInFs] = useState<MappingField[]>([]);
  const [outFsMap, setOutFsMap] = useState<{
    [key: string]: MappingField;
  }>({});
  const [transform, setTransform] = useState<string>();

  const setStateKeyValue = (key: string) => (value: any) => {
    setState(
      produce((state) => {
        state[key] = value;
      }),
    );
  };

  const setFormula = (
    fs: MappingField[] | ((fs: MappingField[]) => MappingField[]),
  ) => {
    setState(
      produce((state) => {
        state.formula = typeof fs === "function" ? fs(state.formula) : fs;
      }),
    );
  };

  const setSystem = setStateKeyValue("system");
  const setProject = setStateKeyValue("project");
  const setModel = setStateKeyValue("model");
  const setClass = setStateKeyValue("class");
  const setDataset = setStateKeyValue("dataset");
  const setTimeDependent = setStateKeyValue("timeDependent");
  const setFolder = setStateKeyValue("folder");

  useEffect(() => {
    const KSgetSystemList = async () => {
      const getSystems = await KSService.getAll();
      setKSSystemOptions(
        getSystems.data.map((el: any) => ({ label: el.id, value: el.id })),
      );
    };

    setTransform(undefined);

    const node = nodes.find((node) => node.id === id);

    if (node) {
      setState(
        produce((state) => {
          state.system = node.data.system;
          state.project = node.data.project;
          state.model = node.data.model;
          state.class = node.data.class;
          state.dataset = node.data.dataset;
          state.timeDependent = node.data.timeDependent;
          state.folder = node.data.folder;
          state.formula = node.data.formula || [];
        }),
      );
    }

    KSgetSystemList();

    IntegrationManager.initial(id, nodes, edges, setInFs, setFormula);
  }, [id]);

  const [ksSystemOptions, setKSSystemOptions] = useState<Option[]>([]);
  const [ksProjectOptions, setKSProjectOptions] = useState<Option[]>([]);
  const [ksClassOptions, setKSClassOptions] = useState<Option[]>([]);
  const [ksModelOptions, setKSModelOptions] = useState<Option[]>([]);
  const [ksDatasetOptions, setKSDatasetOptions] = useState<Option[]>([]);

  useEffect(() => {
    const KSgetProjectList = async () => {
      if (!state.system) {
        return;
      }

      const getProjects = await KSService.getProjects(state.system);
      if (getProjects.code === 1) {
        setKSProjectOptions((prevOptions: any) =>
          getProjects.data.map((el: any) => ({
            label: el.name,
            value: el.uuid,
          })),
        );
      } else {
        notification.error({
          message: getProjects.text,
          description: `Код ответа: ${getProjects.code}`,
        });
      }
    };

    KSgetProjectList();
  }, [state.system]);

  useEffect(() => {
    const KSgetClassList = async () => {
      const getClasses = await KSService.getClasses(
        state.system,
        state.project,
      );
      if (getClasses.code === 1) {
        setKSClassOptions((prevOptions: any) =>
          getClasses.data.map((el: any) => ({
            label: el.name,
            value: el.uuid,
          })),
        );
      }
    };

    KSgetClassList();
  }, [state.project, state.system]);

  useEffect(() => {
    const KSgetModelList = async () => {
      const getModels = await KSService.getModels(state.system, state.project);
      if (getModels.code === 1) {
        setKSModelOptions((prevOptions: any) =>
          getModels.data.map((el: any) => ({
            label: el.name,
            value: el.uuid,
          })),
        );
      }
    };

    KSgetModelList();
  }, [state.project, state.system]);

  useEffect(() => {
    const KSgetFigures = async () => {
      if (!state.system || !state.project || !state.class) {
        return;
      }

      const getFigures = await KSService.getFigures(
        state.system,
        state.project,
        state.class,
      );

      if (getFigures.code !== 1) {
        return;
      }

      const fields = [
        {
          uuid: OBJECT_UUID,
          id: "_Object",
          type: "STRING",
          desc: "Наименование объекта",
          figure: false,
        },
      ];

      if (state.timeDependent) {
        fields.unshift({
          uuid: DATE_UUID,
          id: "_Date",
          type: "DATE",
          desc: "Дата для показателей со временем",
          figure: false,
        });
      }

      getFigures.data.forEach((figure) => {
        if (figure.canBeTimed !== state.timeDependent) return;

        fields.push({
          uuid: figure.uuid,
          id: getCyrillic(figure.name.replace(/[^A-Za-zА-Яа-яёЁ0-9]/g, "")),
          type: figure.dataType === "number" ? "DECIMAL" : "STRING",
          desc: figure.name,
          figure: true,
        });
      });

      setFormula((fs) =>
        fields.map((field: any) => {
          const f = fs.find((f) => f.uuid === field.uuid);
          const formula = f ? f.formula : undefined;
          return { ...field, formula };
        }),
      );
    };

    KSgetFigures();
  }, [state.system, state.project, state.class, state.timeDependent]);

  useEffect(() => {
    const KSgetDataset = async () => {
      if (!state.system || !state.project || !state.model) {
        return;
      }

      const getDatasets = await KSService.getDatasets(
        state.system,
        state.project,
        state.model,
      );
      if (getDatasets.code === 1) {
        setKSDatasetOptions((prevOptions: any) =>
          getDatasets.data.map((el: any) => ({
            label: el.name,
            value: el.uuid,
          })),
        );
      }
    };

    KSgetDataset();
  }, [state.system, state.project, state.model]);

  useEffect(() => {
    if (state.formula.length > 0) {
      setOutFsMap(
        produce((mapping) => {
          state.formula.forEach((f) => {
            mapping[f.uuid] = f;
          });
        }),
      );
    }
  }, [state.formula]);

  const textAreaValue = outFsMap[transform]?.formula || "";

  const onMappingParamChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLTextAreaElement>) => {
    setState(
      produce((state) => {
        state.formula.forEach((f) => {
          if (f.uuid === transform) {
            f.formula = value;
          }
        });
      }),
    );
  };

  const makeFormulaUpdater = (uuid: string, key: string) => (value: any) => {
    setFormula(
      produce((fields: any[]) => {
        fields.forEach((field) => {
          if (field.uuid === uuid) {
            field[key] = value;
          }
        });
      }),
    );
  };

  return (
    <SContent>
      <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>
            Подключение KS
          </div>
          <Select
            value={state.system}
            style={{ width: "300px", color: "black" }}
            onChange={(el) => setSystem(el)}
            options={ksSystemOptions}
            showSearch={true}
            filterOption={(input, option?: Option) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>Проект KS</div>
          <Select
            value={state.project}
            onChange={setProject}
            style={{ width: "300px", color: "black" }}
            options={ksProjectOptions}
            showSearch={true}
            filterOption={(input, option?: Option) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>Модель KS</div>
          <Select
            value={state.model}
            onChange={setModel}
            style={{ width: "300px", color: "black" }}
            options={ksModelOptions}
            showSearch={true}
            filterOption={(input, option?: Option) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>Датасет KS</div>
          <Select
            value={state.dataset}
            onChange={setDataset}
            style={{ width: "300px", color: "black" }}
            options={ksDatasetOptions}
            showSearch={true}
            filterOption={(input, option?: Option) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>Класс KS</div>
          <Select
            value={state.class}
            onChange={setClass}
            style={{ width: "300px", color: "black" }}
            options={ksClassOptions}
            showSearch={true}
            filterOption={(input, option?: Option) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>
            Путь создания объектов
          </div>
          <Input
            value={state.folder}
            style={{ width: "300px", color: "black" }}
            onChange={R.pipe(R.path(["target", "value"]), setFolder)}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>
            Времязависимые
          </div>
          <Switch checked={state.timeDependent} onChange={setTimeDependent} />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "50px",
            height: "500px",
            overflow: "auto",
          }}
        >
          <div>
            <span style={{ fontWeight: "bold" }}>⤵️ Входящий поток</span>
            <div>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "20px",
                        padding: "10px",
                        height: "32px",
                      }}
                    ></TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "100px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Идентификатор
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "250px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Описание
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "100px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Тип
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {inFs.length > 0 ? (
                    inFs.map((f, i) => (
                      <TableRow key={i}>
                        <TableCell style={{ padding: "10px", height: "32px" }}>
                          {IntegrationFieldsManager.getSourceFieldStatus(
                            f.id,
                            state.formula,
                          )}
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "32px" }}>
                          {f.id}
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "32px" }}>
                          {f.desc}
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "32px" }}>
                          {f.type}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4}>
                        <Empty
                          imageStyle={{ height: "50px" }}
                          description="Нет входящих полей"
                        />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </div>
          <div>
            <span style={{ fontWeight: "bold" }}>⤴️ Исходящий поток</span>
            <div>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "20px",
                        padding: "10px",
                        height: "32px",
                      }}
                    ></TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "100px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Идентификатор
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "250px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Описание
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "100px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Тип
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        width: "100px",
                        padding: "10px",
                        height: "32px",
                      }}
                    >
                      Действие
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {state.formula.length > 0 ? (
                    state.formula.map((f, i) => (
                      <TableRow key={i}>
                        <TableCell style={{ padding: "10px", height: "32px" }}>
                          {f.formula && f.formula.length > 0 ? "✅" : "⭕"}
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "25px" }}>
                          <Popover content={outFsMap[f.uuid]?.id || ""}>
                            <Input
                              disabled={true}
                              value={outFsMap[f.uuid]?.id || ""}
                              onChange={R.pipe(
                                R.path(["target", "value"]),
                                makeFormulaUpdater(f.uuid, "id"),
                              )}
                            />
                          </Popover>
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "25px" }}>
                          <Popover content={outFsMap[f.uuid]?.desc || ""}>
                            <Input
                              disabled={true}
                              value={outFsMap[f.uuid]?.desc || ""}
                              onChange={R.pipe(
                                R.path(["target", "value"]),
                                makeFormulaUpdater(f.uuid, "desc"),
                              )}
                            />
                          </Popover>
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "25px" }}>
                          <Popover content={outFsMap[f.uuid]?.type || ""}>
                            <Select
                              style={{ width: "100%" }}
                              value={outFsMap[f.uuid]?.type || ""}
                              onChange={makeFormulaUpdater(f.uuid, "type")}
                              options={__optionType}
                              disabled={true}
                            />
                          </Popover>
                        </TableCell>
                        <TableCell style={{ padding: "10px", height: "25px" }}>
                          <Button onClick={() => setTransform(f.uuid)}>
                            🔎
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={100}>
                        <Empty
                          imageStyle={{ height: "50px" }}
                          description="Нет исходящих полей"
                        />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <span>
            Параметр мэппинга:{" "}
            {state.formula &&
              state.formula.find((f) => f.uuid === transform)?.id}
          </span>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
              height: "100px",
            }}
          >
            <Input.TextArea
              size="large"
              style={{ height: "100px", resize: "none" }}
              disabled={!transform}
              value={textAreaValue}
              onChange={onMappingParamChange}
            />
            <Button
              style={{ width: "100px", height: "100px", fontSize: "36px" }}
              onClick={() => console.log({ nodes, edges })}
            >
              👁
            </Button>
          </div>
        </div>
      </div>
    </SContent>
  );
};

export default KnowledgeSpaceClassOutput;

const __optionType = [
  {
    label: "Строка",
    options: [
      { value: "STRING", label: "STRING" },
      { value: "TEXT", label: "TEXT" },
    ],
  },
  {
    label: "Число",
    options: [
      { value: "INTEGER", label: "INTEGER" },
      { value: "DECIMAL", label: "DECIMAL" },
    ],
  },
  {
    label: "Дата и время",
    options: [
      { value: "DATE", label: "DATE" },
      { value: "DATETIME", label: "DATETIME" },
    ],
  },
  {
    label: "Булевы",
    options: [{ value: "BOOL", label: "BOOLEAN" }],
  },
];
