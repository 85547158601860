import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Node } from "@xyflow/react";
import { Input, InputNumber, Popover, Select } from "antd";
import Empty from "antd/lib/empty";
import { produce } from "immer";
import { pick } from "lodash";
import * as R from "ramda";
import { useContext, useEffect, useMemo, useState } from "react";
import { useICState } from "../../state";
import { Context, SContent } from "../components";
import { MappingField } from "../utils";

type Props = {
  id: string;
};

type State = {
  fcstMethod: string;
  fcstTimeLevel: string;
  fcstLength: number;
  fcstOffset: number;
  methodParameters: any[];
  formula: MappingField[];
};

const initialState: State = {
  fcstMethod: "",
  fcstTimeLevel: "",
  fcstLength: 0,
  fcstOffset: 0,
  methodParameters: [],
  formula: [],
};

const ExtrapolationWindow: React.FC<Props> = ({ id }) => {
  const { nodes, edges, setNodes } = useICState();
  const { actions } = useContext(Context);

  const [state, setState] = useState<State>(initialState);

  useEffect(() => {
    actions.save = () => {
      setNodes(
        produce((nodes: Node[]) => {
          const node = nodes.find((node) => node.id === id);

          node.data.fcstMethod = state.fcstMethod;
          node.data.fcstTimeLevel = state.fcstTimeLevel;
          node.data.fcstLength = state.fcstLength;
          node.data.fcstOffset = state.fcstOffset;
          node.data.methodParameters = state.methodParameters;
          node.data.formula = state.formula;
        }),
      );
    };
  });

  const setStateKeyValue =
    (key: string) => (value: any | ((value: any) => any)) => {
      setState(
        produce((state) => {
          state[key] = typeof value === "function" ? value(state[key]) : value;
        }),
      );
    };

  const setFcstMethod = setStateKeyValue("fcstMethod");
  const setFcstTimeLevel = setStateKeyValue("fcstTimeLevel");
  const setFcstLength = setStateKeyValue("fcstLength");
  const setFcstOffset = setStateKeyValue("fcstOffset");
  const setMethodParameters = setStateKeyValue("methodParameters");
  const setFormula = setStateKeyValue("formula");

  const [outFsMap, setOutFsMap] = useState<{ [key: string]: MappingField }>({});

  useEffect(() => {
    const node = nodes.find((node) => node.id === id)!;

    const fields: any[] = [];

    const leftEdge = edges.find((edge) => edge.target === id);
    if (leftEdge) {
      const leftNode = nodes.find((node) => node.id === leftEdge.source)!;

      ((leftNode.data.formula as any[]) ?? []).forEach((field) => {
        const findF = [...((node.data.formula as any[]) ?? [])].find(
          (f) => f.id === field.id,
        );
        const oldF = pick(field, ["uuid", "id", "type", "flow", "formula"]);
        const newF = findF ? { ...oldF, ...findF } : oldF;
        fields.push(newF);
      });
    }

    const forecastMethod: any = node.data.fcstMethod;

    if (forecastMethod) {
      setFcstMethod(forecastMethod);
    }

    const fcstTimeLevel: any = node.data.fcstTimeLevel;

    if (fcstTimeLevel) {
      setFcstTimeLevel(fcstTimeLevel);
    }

    const fcstLength: any = node.data.fcstLength;

    if (fcstLength) {
      setFcstLength(fcstLength);
    }

    const fcstOffset: any = node.data.fcstOffset;

    if (fcstOffset) {
      setFcstOffset(fcstOffset);
    }

    const methodParameters: any = node.data.methodParameters;

    if (methodParameters && methodParameters.length > 0) {
      console.log("setting", methodParameters);
      setMethodParameters(methodParameters);
    }

    setFormula(fields);
  }, [id]);

  useEffect(() => {
    if (state.formula && state.formula.length > 0) {
      setOutFsMap(
        produce((fields) => {
          state.formula.forEach((field) => {
            fields[field.uuid] = field;
          });
        }),
      );
    }
  }, [state.formula]);

  const generateMethodParametersElements = useMemo(() => {
    const fields = [];

    const getValue = (fieldId: string) => {
      if (!state.methodParameters) {
        return undefined;
      }
      console.log(state.methodParameters);
      const parameter = state.methodParameters.find(
        (el: any) => el.id === fieldId,
      );
      return parameter ? parameter.value : undefined;
    };

    switch (state.fcstMethod) {
      case "MOVAVG":
        fields.push({
          id: "HISTORY",
          label: "Глубина истории",
          type: "NUMBER",
          value: getValue("HISTORY"),
        });
        break;
      case "QUANTILE":
        fields.push({
          id: "QUANTILE",
          label: "Квантиль",
          type: "NUMBER",
          value: getValue("QUANTILE"),
        });
        break;
      case "EXPSMOOTH":
        fields.push({
          id: "ALPHA",
          label: "Альфа",
          type: "NUMBER",
          value: getValue("ALPHA"),
        });
        break;
      default:
        break;
    }

    return fields.map((parameter: any) => {
      const setValue = (value: any) => {
        setMethodParameters((prev: any) => {
          if (prev && prev.find((e: any) => e.id === parameter.id)) {
            return prev.map((pr: any) => {
              if (pr.id === parameter.id) {
                return { ...pr, value };
              }
              return pr;
            });
          } else {
            prev.push({ ...parameter, value });
            return prev;
          }
        });
      };

      return (
        <div
          key={parameter.id}
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>
            {parameter.label}
          </div>
          {parameter.type === "NUMBER" && (
            <InputNumber
              style={{ width: "300px", color: "black" }}
              value={Number(parameter.value)}
              onChange={(e) => setValue(Number(e))}
            />
          )}
          {parameter.type === "STRING" && (
            <Input
              style={{ width: "300px", color: "black" }}
              value={parameter.value}
              onChange={(e) => setValue(e.target.value)}
            />
          )}
        </div>
      );
    });
  }, [state.fcstMethod, state.methodParameters]);

  const makeFormulaUpdater = (uuid: string, key: string) => (value: any) => {
    setFormula(
      produce((fields: any[]) => {
        fields.forEach((field) => {
          if (field.uuid === uuid) {
            field[key] = value;
          }
        });
      }),
    );
  };

  return (
    <SContent>
      <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>
            Метод прогнозирования
          </div>
          <Select
            style={{ width: "300px", color: "black" }}
            options={__forecastOptions}
            value={state.fcstMethod}
            onChange={setFcstMethod}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>
            Временная гранулярность
          </div>
          <Select
            style={{ width: "300px", color: "black" }}
            options={__forecastPeriods}
            value={state.fcstTimeLevel}
            onChange={setFcstTimeLevel}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>
            Кол-во периодов
          </div>
          <InputNumber
            style={{ width: "300px", color: "black" }}
            value={Number(state.fcstLength)}
            onChange={(e) => setFcstLength(Number(e))}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>
            Смещение по времени
          </div>
          <InputNumber
            style={{ width: "300px", color: "black" }}
            value={Number(state.fcstOffset)}
            onChange={(e) => setFcstOffset(Number(e))}
          />
        </div>
        {generateMethodParametersElements}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "50px",
            height: "500px",
            overflow: "auto",
          }}
        >
          <div>
            <span style={{ fontWeight: "bold" }}>
              ⤴️ Входящий/Исходящий поток
            </span>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      width: "20px",
                      padding: "10px",
                      height: "32px",
                    }}
                  ></TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      width: "100px",
                      padding: "10px",
                      height: "32px",
                    }}
                  >
                    Идентификатор
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      width: "250px",
                      padding: "10px",
                      height: "32px",
                    }}
                  >
                    Описание
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      width: "100px",
                      padding: "10px",
                      height: "32px",
                    }}
                  >
                    Тип
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      width: "100px",
                      padding: "10px",
                      height: "32px",
                    }}
                  >
                    Тип поля
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.formula?.length > 0 ? (
                  state.formula.map((f: MappingField, i: number) => (
                    <TableRow key={i}>
                      <TableCell style={{ padding: "10px", height: "32px" }}>
                        {f.forecastFieldType ? "✅" : "⭕"}
                      </TableCell>
                      <TableCell style={{ padding: "10px", height: "25px" }}>
                        <Popover content={outFsMap[f.uuid]?.id || ""}>
                          <Input
                            value={outFsMap[f.uuid]?.id || ""}
                            onChange={R.pipe(
                              R.pick(["target", "value"]),
                              makeFormulaUpdater(f.uuid, "id"),
                            )}
                            disabled={true}
                          />
                        </Popover>
                      </TableCell>
                      <TableCell style={{ padding: "10px", height: "25px" }}>
                        <Popover content={outFsMap[f.uuid]?.desc || ""}>
                          <Input
                            value={outFsMap[f.uuid]?.desc || ""}
                            onChange={R.pipe(
                              R.pick(["target", "value"]),
                              makeFormulaUpdater(f.uuid, "desc"),
                            )}
                            disabled={true}
                          />
                        </Popover>
                      </TableCell>
                      <TableCell style={{ padding: "10px", height: "25px" }}>
                        <Popover content={outFsMap[f.uuid]?.type || ""}>
                          <Select
                            style={{ width: "100%" }}
                            value={outFsMap[f.uuid]?.type || ""}
                            onChange={makeFormulaUpdater(f.uuid, "type")}
                            options={__optionType}
                            disabled={true}
                          />
                        </Popover>
                      </TableCell>
                      <TableCell style={{ padding: "10px", height: "25px" }}>
                        <Select
                          style={{ width: "100%" }}
                          options={__fieldType}
                          onChange={makeFormulaUpdater(
                            f.uuid,
                            "forecastFieldType",
                          )}
                          value={outFsMap[f.uuid]?.forecastFieldType}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={100}>
                      <Empty
                        imageStyle={{ height: "50px" }}
                        description="Нет входящих полей"
                      />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    </SContent>
  );
};

export default ExtrapolationWindow;

const __optionType = [
  {
    label: "Строка",
    options: [
      { value: "STRING", label: "STRING" },
      { value: "TEXT", label: "TEXT" },
    ],
  },
  {
    label: "Число",
    options: [
      { value: "INTEGER", label: "INTEGER" },
      { value: "DECIMAL", label: "DECIMAL" },
    ],
  },
  {
    label: "Дата и время",
    options: [
      { value: "DATE", label: "DATE" },
      { value: "DATETIME", label: "DATETIME" },
    ],
  },
  {
    label: "Булевы",
    options: [{ value: "BOOL", label: "BOOLEAN" }],
  },
];

const __fieldType = [
  {
    value: "ATTRIBUTE",
    label: "Атрибут",
  },
  {
    value: "FIGURE",
    label: "Показатель",
  },
  {
    value: "DATE",
    label: "Дата",
  },
];

const __forecastOptions = [
  {
    value: "AVG",
    label: "Арифметическое среднее",
  },
  {
    value: "MOVAVG",
    label: "Скользящее среднее",
  },
  {
    value: "MEDIAN",
    label: "Медиана",
  },
  {
    value: "STDEV",
    label: "Стандартное отклонение",
  },
  {
    value: "VARIANCE",
    label: "Дисперсия",
  },
  {
    value: "QUANTILE",
    label: "Квантиль",
  },
  {
    value: "SIMLINREG",
    label: "Простая линейная регрессия",
  },
  {
    value: "EXPSMOOTH",
    label: "Экспоненциальное сглаживание",
    disabled: true,
  },
  {
    value: "PROPHET",
    label: "Prophet",
    disabled: true,
  },
  {
    value: "GRADIENT-BOOSTING",
    label: "Градиентный бустинг",
    disabled: true,
  },
  {
    value: "HOLT-WINTERS",
    label: "Holt-Winters",
    disabled: true,
  },
];

const __forecastPeriods = [
  {
    value: "DAY",
    label: "День",
  },
  {
    value: "WEEK",
    label: "Неделя",
  },
  {
    value: "MONTH",
    label: "Месяц",
  },
  {
    value: "QUARTER",
    label: "Квартал",
  },
  {
    value: "YEAR",
    label: "Год",
  },
];
