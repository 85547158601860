import { InboxOutlined, PlusOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Input } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { Edge, Node, useReactFlow } from "@xyflow/react";
import {
  Button,
  Divider,
  Empty,
  InputRef,
  message,
  Modal,
  notification,
  Popover,
  Select,
  Space,
  Upload,
  UploadProps,
} from "antd";
import ELK from "elkjs/lib/elk.bundled.js";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { v4 as uuidv4 } from "uuid";
import FlowService from "../../../entities/model/FlowService";
import UserPopover from "../../../shared/components/popovers/user";
import { fuzzyIsIn } from "../../../shared/helper/comparison";
import IntegrationFlowLaunch from "../integrationTaskv4/flowLaunch";
import IntegrationFlowLogs from "./flowLogs";
import ModalFlowVariables, { IFlowVariableField } from "./modalVariables";
import { NEW_INTEGRATION_ID, useICLibraryStore, useICState } from "./state";

import { ReactComponent as AutoAlignmentIcon } from "../../../assets/auto_alignment.svg";
import { ReactComponent as ClearIcon } from "../../../assets/clear.svg";
import { ReactComponent as DublicateIcon } from "../../../assets/dublicate.svg";
import { ReactComponent as ExportSettingsIcon } from "../../../assets/export_settings.svg";
import { ReactComponent as FileIcon } from "../../../assets/file.svg";
import { ReactComponent as ImportSettingsIcon } from "../../../assets/import_settings.svg";
import { ReactComponent as LogDataIcon } from "../../../assets/log_data.svg";
import { ReactComponent as PasteIcon } from "../../../assets/paste.svg";
import { ReactComponent as PersonIcon } from "../../../assets/person.svg";
import { ReactComponent as PlayCircleFilledIcon } from "../../../assets/play_circle_filled.svg";
import { ReactComponent as RedoIcon } from "../../../assets/redo.svg";
import { ReactComponent as SaveIcon } from "../../../assets/save.svg";
import { ReactComponent as UndoIcon } from "../../../assets/undo.svg";
import { ReactComponent as VariableIcon } from "../../../assets/variable.svg";

const SMenuContainer = styled.div({
  width: "100%",
  height: "42px",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "8px",
  backgroundColor: "var(--Primary-Grey-025)",
  padding: "1px 20px 1px 16px",
});

const SMenuButtonsContainer = styled.div({
  width: "488px",
  height: "100%",
  display: "flex",
  justifyContent: "space-between",
});

const SMenuButtonsHeader = styled.div({
  padding: "6px 0px",
});

const SMenuButtonsHeaderItem = styled.div(
  ({ isActive = false }: { isActive: boolean }) => ({
    borderRadius: "4px",
    padding: "2px 4px",
    backgroundColor: isActive
      ? "var(--Primary-400)"
      : "var(--Primary-Grey-100)",

    ":hover": {
      backgroundColor: isActive
        ? "var(--Primary-600)"
        : "var(--Primary-Grey-400)",
    },
  }),
);

const SMenuButtonsHeaderItemIcon = styled.div({
  width: "24px",
  height: "24px",
  cursor: "pointer",

  "*": {
    width: "100%",
    height: "100%",
    fill: "var(--White)",
  },
});

const SMenuButtonsBody = styled.div({
  width: "440px",
  display: "flex",
  flexDirection: "row",
  gap: "0px",
});

const SMenuButtonsBodyItem = styled.div(() => ({
  width: "40px",
  height: "40px",
  padding: "8px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  cursor: "pointer",

  "*": {
    width: "100%",
    height: "100%",
    fill: "var(--Primary-Grey-100)",
  },

  ":hover *": {
    fill: "var(--Primary-400)",
  },

  ":active *": {
    fill: "var(--Primary-600)",
  },
}));

const SMenuButtonsBodyItemIcon = styled.div({
  width: "24px",
  height: "24px",
});

const SMenuInputContainer = styled.div({
  width: "190.5px",
  height: "100%",
  padding: 0,
});

const SMenuDateContainer = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "4px",
  pointerEvents: "none",
});

const SMenuDateIcon = styled.div({
  width: "18px",
  height: "18px",
  "*": {
    width: "100%",
    height: "100%",
    fill: "var(--Primary-Grey-100)",
  },
});

const SMenuDateDate = styled.div({
  color: "var(--Primary-Grey-400)",
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "13px",
  textAlign: "center",
});

const SMenuDateTime = styled.div({
  color: "var(--Primary-Grey-100)",
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "13px",
  textAlign: "center",
});

const SMenuUserContainer = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "4px",
  pointerEvents: "none",
});

const SMenuUserIcon = styled.div({
  width: "18px",
  height: "18px",
  "*": {
    width: "100%",
    height: "100%",
    fill: "var(--Primary-Grey-100)",
  },
});

const SMenuUserText = styled.div({
  color: "var(--Primary-Grey-400)",
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "13px",
  textAlign: "center",
  pointerEvents: "auto",
  cursor: "default",
});

interface IExportFlow {
  data: any;
  filename: string;
}

const ExportFlow = ({ data, filename }: IExportFlow) => {
  const dataString = JSON.stringify(data);
  const fileData = new Blob([dataString], { type: "text/json" }); // Создайте объект Blob
  const fileName = `${filename}.json`;

  window.webkitURL.createObjectURL(fileData);
  const link = document.createElement("a");
  link.href = window.webkitURL.createObjectURL(fileData);
  link.download = fileName;
  link.click();
  window.webkitURL.revokeObjectURL(link.href);
};

interface IImportFlow {
  setNodes: Dispatch<SetStateAction<Node[]>>;
  setEdges: Dispatch<SetStateAction<Edge[]>>;
  setVariables: Dispatch<SetStateAction<IFlowVariableField[]>>;
  setProject: Dispatch<SetStateAction<string | null>>;

  closeModal: any;
}
const ImportFlow: React.FC<IImportFlow> = ({
  setNodes,
  setEdges,
  setVariables,
  setProject,
  closeModal,
}) => {
  const { Dragger } = Upload;

  const props: UploadProps = {
    name: "file",
    multiple: false,
    beforeUpload: async (file) => {
      const value = JSON.parse(await file.text());
      if (!value.flowId || !value.nodes || !value.edges) {
        return message.error(
          "Загружаемый файл поврежден, так как не содержит необходимых данных!",
        );
      }
      setNodes(value.nodes);
      setEdges(value.edges);
      setVariables(value.variables);
      setProject(value.project);
      message.success("Успешно импортировано!");
      closeModal();
    },
  };
  return (
    <Dragger {...props}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">
        Нажмите или перетащите JSON файл потока для импорта
      </p>
      <p className="ant-upload-hint">
        Обратите внимание, что это действие перезапишет все текущие ноды. Для
        принятия изменений потребуется сохранить поток.
      </p>
    </Dragger>
  );
};

const elk = new ELK();

const useLayoutedElements = () => {
  const { getNodes, setNodes, getEdges, fitView } = useReactFlow();
  const defaultOptions = {
    "elk.algorithm": "layered",
    "elk.layered.spacing.nodeNodeBetweenLayers": 100,
    "elk.spacing.nodeNode": 100,
  };

  const getLayoutedElements = useCallback((options: any) => {
    const layoutOptions = { ...defaultOptions, ...options };
    const graph: any = {
      id: "root",
      layoutOptions: layoutOptions,
      children: getNodes().map((e: any) => ({
        ...e,
        width: e.measured.width,
        height: e.measured.height,
      })),
      edges: getEdges(),
    };

    elk.layout(graph).then(({ children }: any) => {
      children.forEach((node: any) => {
        node.position = { x: node.x, y: node.y };
      });

      setNodes(children);
      window.requestAnimationFrame(() => {
        fitView();
      });
    });
  }, []);

  return { getLayoutedElements };
};

export const Menu = () => {
  const isOpen = useICLibraryStore((state) => state.isOpen);
  const toggle = useICLibraryStore((state) => state.toggle);

  const {
    id,
    flowId,
    nodes,
    edges,
    variables,
    setNodes,
    setEdges,
    project,
    flowChangedAt,
    flowChangedBy,
    fetchData,
    setProject,
    history: historical,
    undoCount,
    setUndoCount,
    lastState,
    setFlowId,
    setVariables,
  } = useICState();

  const [launchModalState, setLaunchModalState] = useState<boolean>(false);
  const [logsModalState, setLogsModalState] = useState<boolean>(false);
  const [importModalState, setImportModalState] = useState<boolean>(false);
  const [variableModalState, setVariableModalState] = useState<boolean>(false);

  const openLaunchDrawer = () => {
    setLaunchModalState(true);
  };

  const closeLaunchDrawer = () => {
    setLaunchModalState(false);
  };

  const openLogsModal = () => {
    setLogsModalState(true);
  };

  const closeLogsModal = () => {
    setLogsModalState(false);
  };

  const openImportModal = () => {
    setImportModalState(true);
  };

  const closeImportModal = () => {
    setImportModalState(false);
  };

  const openVariableModal = () => {
    setVariableModalState(true);
  };

  const { getLayoutedElements } = useLayoutedElements();

  const handleSave = async () => {
    if (!flowId || flowId === "") {
      return message.error("Укажите идентификатор потока!");
    }

    let redirect: boolean = false;

    if (id && id !== NEW_INTEGRATION_ID && id !== flowId) {
      const response = await FlowService.rename(id, flowId);
      if (response.code === 1) {
        redirect = true;
        notification.success({ message: "Успешно переименовано" });
      } else {
        notification.error({
          message: response.text,
          description: `Код ответа: ${response.code}`,
        });
      }
    }

    const project_string = project ? project : "";

    setEdges((prev: any) => {
      return prev.filter((edge: any) => {
        const sourceCheck = nodes.find((node: any) => node.id === edge.source);
        const targetCheck = nodes.find((node: any) => node.id === edge.target);
        if (sourceCheck && targetCheck) {
          return true;
        }
      });
    });

    if (id === NEW_INTEGRATION_ID) {
      const response = await FlowService.create(
        flowId,
        project_string,
        nodes,
        edges,
        variables,
      );
      if (response.code === 1) {
        window.location.href = `/integration/constructor/${flowId}`;
        return notification.success({ message: "Успешно создано" });
      } else {
        return notification.error({
          message: response.text,
          description: `Код ответа: ${response.code}`,
        });
      }
    } else {
      const response = await FlowService.update(
        flowId,
        project_string,
        nodes,
        edges,
        variables,
      );
      if (response.code === 1) {
        if (redirect) {
          window.location.href = `/integration/constructor/${flowId}`;
        }
        await fetchData();
        return notification.success({ message: "Успешно сохранено" });
      } else {
        if (redirect) {
          window.location.href = `/integration/constructor/${flowId}`;
        }
        return notification.error({
          message: response.text,
          description: `Код ответа: ${response.code}`,
        });
      }
    }
  };

  const { data: projects_raw = [] } = useQuery<string[]>({
    queryKey: ["flow-projects"],
    queryFn: async () => {
      const response = await FlowService.getProjects();

      if (response.code !== 1) {
        throw new Error(
          `Ошибка при попытке загрузки списка заданий интеграции: "${response.text}"`,
        );
      }

      return response.data;
    },
  });

  const [newProject, setNewProject] = useState("");
  const [projects, setProjects] = useState(projects_raw);
  useEffect(() => setProjects(projects_raw), [projects_raw]);
  const inputRef = useRef<InputRef>(null);

  const addProject = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>,
  ) => {
    e.preventDefault();
    setProjects([...projects, newProject]);
    setNewProject("");
    setProject(newProject);
    setTimeout(() => inputRef.current?.focus(), 0);
  };

  const undo = () => {
    console.log(historical, undoCount);
    setUndoCount((prev: any) => {
      if (historical.length === 0 || historical.length - 2 - prev < 0) {
        message.warning("Более глубокая история изменений отсутствует.");
        return prev;
      }

      const version = historical[historical.length - 2 - prev];
      console.log("versiyaaa", version);

      setNodes(version.nodes);
      setEdges(version.edges);

      return prev + 1;
    });
  };

  const redo = () => {
    console.log(historical, undoCount);
    setUndoCount((prev: any) => {
      // Ensure there are redo actions available
      if (historical.length === 0 || prev <= 0) {
        setNodes(lastState.nodes);
        setEdges(lastState.edges);
        message.warning("Нет изменений для повтора.");
        return prev;
      }

      // Calculate the redo version based on the current undoCount
      const version = historical[historical.length - prev];

      // Update the state with the redo version
      setNodes(version.nodes);
      setEdges(version.edges);

      // Increment the undo count to reflect the redo operation
      return prev - 1;
    });
  };

  const Copy = async () => {
    const copyNodes = nodes.filter((node: any) => node.selected);
    const copyEdges = edges.filter((edge: any) => edge.selected);

    const object = {
      type: "IBPMASTER_INTEGRATION_COPYPASTE",
      nodes: copyNodes,
      edges: copyEdges,
    };

    console.log(nodes);

    try {
      await navigator.clipboard.writeText(JSON.stringify(object));
      message.success(`Копирование ${copyNodes.length} нод произошло успешно!`);
    } catch (text: any) {
      message.error("Не удалось произвести копирование. Ошибка доступа.");
    }
  };

  const Paste = async () => {
    try {
      const rawText = await navigator.clipboard.readText();
      const o = JSON.parse(rawText);

      if (
        Object.hasOwn(o, "type") &&
        Object.hasOwn(o, "nodes") &&
        Object.hasOwn(o, "edges") &&
        o.type === "IBPMASTER_INTEGRATION_COPYPASTE"
      ) {
        if (typeof o.nodes !== "object" || o.nodes.length === 0) {
          return message.warning("Вставлено 0 нод!");
        }

        const pasteEdges: Edge[] = [];
        const pasteNodes: Node[] = [];

        for (const node of o.nodes) {
          let i: number = 0;
          let stopper = false;
          let label: string = node.data.label;
          while (!stopper) {
            const labelToFind = `${label}${i > 0 ? `[${i}]` : ""}`;
            const find = nodes.some(
              (node: any) => node.data.label === labelToFind,
            );
            if (find) {
              i++;
              continue;
            } else {
              label = labelToFind;
              stopper = true;
            }
          }

          const id = `${node.type}_${uuidv4().replaceAll("-", "")}`;

          // в соединениях надо заменить старый идентификатор на новый
          o.edges = o.edges.map((el: any) => {
            if (el.source === node.id) {
              el.source = id;
            }
            if (el.target === node.id) {
              el.target = id;
            }
            return el;
          });

          pasteNodes.push({
            id,
            selected: true,
            type: node.type,
            position: node.position,
            data: {
              ...node.data,
              label,
            },
          });
        }

        for (const edge of o.edges) {
          pasteEdges.push({
            id: `xy-edge-pasted-${uuidv4()}`,
            selected: true,
            source: edge.source,
            sourceHandle: edge.sourceHandle,
            target: edge.target,
            targetHandle: edge.targetHandle,
            type: edge.type,
          });
        }

        setNodes((nodes: any) => [...nodes, ...pasteNodes]);
        setEdges((edges: any) => [...edges, ...pasteEdges]);

        message.success(`Вставка ${pasteNodes.length} нод прошла успешно!`);
      } else {
        message.warning("Вставка не произошла!");
      }
    } catch (text: any) {
      message.error("Не удалось произвести вставку. Некорректный формат.");
    }
  };

  // // Функция, которая будет вызываться при нажатии Ctrl + C или Command + C
  // const handleKeyboardCopyPaste = (event: any) => {
  //   // Проверяем, что нажаты Ctrl + C или Command + C
  //   if ((event.ctrlKey || event.metaKey) && event.key === "c") {
  //     event.preventDefault(); // Предотвращаем стандартное действие (копирование)
  //     Copy();
  //   }

  //   if ((event.ctrlKey || event.metaKey) && event.key === "v") {
  //     event.preventDefault(); // Предотвращаем стандартное действие (копирование)
  //     Paste();
  //   }
  // };

  // useEffect(() => {
  //   // Добавляем слушатель события keydown при монтировании компонента
  //   document.addEventListener("keydown", handleKeyboardCopyPaste);

  //   // Удаляем слушатель события при размонтировании компонента
  //   return () => {
  //     document.removeEventListener("keydown", handleKeyboardCopyPaste);
  //   };
  // }, []);

  const dateInfo = (() => {
    if (!flowChangedAt) return <SMenuDateTime>Поток не сохранен</SMenuDateTime>;

    const [date, time] = flowChangedAt.split(" ");

    return (
      <>
        <SMenuDateDate>{date}</SMenuDateDate>
        <SMenuDateTime>{time}</SMenuDateTime>
      </>
    );
  })();

  const userInfo = (() => {
    if (!flowChangedBy) return null;

    return (
      <SMenuUserContainer>
        <SMenuUserIcon>
          <PersonIcon />
        </SMenuUserIcon>

        <SMenuUserText>
          <UserPopover
            userId={flowChangedBy}
            color={"var(--Primary-Grey-400)"}
          />
        </SMenuUserText>
      </SMenuUserContainer>
    );
  })();

  const idInput = (
    <Input
      value={flowId ? flowId : ""}
      placeholder="Идентификатор"
      onChange={(el: any) => setFlowId(el.target.value.toUpperCase())}
    />
  );

  const projectInput = (
    <Select
      style={{ width: "100%", height: "100%" }}
      placeholder="Проект"
      value={project}
      options={projects.map((item) => ({ label: item, value: item }))}
      onChange={(project) => setProject(project)}
      showSearch={true}
      filterOption={(input, option?: { label: string; value: string }) =>
        fuzzyIsIn(input, option?.label ?? "")
      }
      dropdownRender={(menu) => (
        <>
          <Space style={{ padding: "4px 8px 0" }}>
            <Input
              placeholder="Новый проект"
              ref={inputRef}
              value={newProject}
              onChange={(e) => setNewProject(e.target.value)}
              onKeyDown={(e) => e.stopPropagation()}
            />
            <Button
              type="dashed"
              icon={<PlusOutlined />}
              onClick={addProject}
            />
          </Space>
          <Divider style={{ margin: "8px 0" }} />
          {menu}
        </>
      )}
    />
  );

  const buttons = [
    {
      icon: SaveIcon,
      description: "Сохранить",
      action: handleSave,
    },
    {
      icon: PlayCircleFilledIcon,
      description: "Запустить",
      action: () =>
        id === NEW_INTEGRATION_ID
          ? message.warning("Сначала поток должен быть создан!")
          : openLaunchDrawer(),
    },
    {
      icon: DublicateIcon,
      description: "Копировать",
      action: Copy,
    },
    {
      icon: PasteIcon,
      description: "Вставить",
      action: Paste,
    },
    {
      icon: UndoIcon,
      description: "Отменить последнее действие",
      action: undo,
    },
    {
      icon: RedoIcon,
      description: "Вернуть последнее действие",
      action: redo,
    },
    {
      icon: LogDataIcon,
      description: "Логи запусков",
      action: openLogsModal,
    },
    {
      icon: AutoAlignmentIcon,
      description: "Автовыравнивание нод",
      action: () =>
        getLayoutedElements({
          "elk.algorithm": "layered",
          "elk.direction": "RIGHT",
        }),
    },
    {
      icon: VariableIcon,
      description: "Переменные потока",
      action: openVariableModal,
    },
    {
      icon: ImportSettingsIcon,
      description: "Импорт настроек потока",
      action: openImportModal,
    },
    {
      icon: ExportSettingsIcon,
      description: "Экспорт настроек потока",
      action: () =>
        ExportFlow({
          filename: id ? id : "DraftFlow",
          data: { flowId, project, nodes, edges, variables },
        }),
    },
  ];

  return (
    <>
      <SMenuContainer>
        <SMenuButtonsContainer>
          <SMenuButtonsHeader>
            <SMenuButtonsHeaderItem isActive={isOpen} onClick={() => toggle()}>
              <SMenuButtonsHeaderItemIcon>
                {isOpen ? <ClearIcon /> : <FileIcon />}
              </SMenuButtonsHeaderItemIcon>
            </SMenuButtonsHeaderItem>
          </SMenuButtonsHeader>
          <SMenuButtonsBody>
            {buttons.map((button, i) => (
              <SMenuButtonsBodyItem key={i} onClick={button.action}>
                <SMenuButtonsBodyItemIcon>
                  <Popover placement="bottom" content={button.description}>
                    <button.icon />
                  </Popover>
                </SMenuButtonsBodyItemIcon>
              </SMenuButtonsBodyItem>
            ))}
          </SMenuButtonsBody>
        </SMenuButtonsContainer>
        <SMenuInputContainer>{idInput}</SMenuInputContainer>
        <SMenuInputContainer>{projectInput}</SMenuInputContainer>
        <SMenuDateContainer>
          <SMenuDateIcon>
            <SaveIcon />
          </SMenuDateIcon>
          {dateInfo}
        </SMenuDateContainer>
        {userInfo}
      </SMenuContainer>
      <IntegrationFlowLaunch
        open={launchModalState}
        onClose={closeLaunchDrawer}
        flowId={flowId}
      />
      <Modal
        open={logsModalState}
        onCancel={closeLogsModal}
        width="1400px"
        footer={(_, { CancelBtn }) => (
          <>
            <CancelBtn />
          </>
        )}
        cancelText="Закрыть"
        closable={false}
      >
        {id && id !== NEW_INTEGRATION_ID && <IntegrationFlowLogs flowId={id} />}
        {id === NEW_INTEGRATION_ID && <Empty description="Поток еще создан" />}
      </Modal>
      <Modal
        open={importModalState}
        onCancel={closeImportModal}
        footer={(_, { CancelBtn }) => (
          <>
            <CancelBtn />
          </>
        )}
        cancelText="Закрыть"
        closable={false}
      >
        <ImportFlow
          setEdges={setEdges}
          setNodes={setNodes}
          setProject={setProject}
          setVariables={setVariables}
          closeModal={closeImportModal}
        />
      </Modal>
      <ModalFlowVariables
        modalState={variableModalState}
        setModalState={setVariableModalState}
        variables={variables}
        setVariables={setVariables}
      />
    </>
  );
};
