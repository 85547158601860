import {
  Button,
  ConfigProvider,
  Form,
  Input,
  Select,
  Tour,
  TourProps,
  notification,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import ruRU from "antd/lib/locale/ru_RU";
import React, { useEffect, useRef, useState } from "react";
import FigureService from "../../../entities/model/FigureService";
import LevelService from "../../../entities/model/LevelService";
import { FigureCreate } from "../../../entities/types/IFigure";
import { fuzzyIsIn } from "../../../shared/helper/comparison";
import { counter } from "../../../shared/helper/counter";
import {
  FigureAggAttrModes,
  FigureAggTimeModes,
  FigureDisAgrModes,
  FigureDisTimeModes,
  FigureType,
} from "./constants";
import type { Figure } from "./types";

type Props = {
  model: string;
  setModalState: any;
  fetchData: any;
};

const FigureCreatePage: React.FC<Props> = ({
  model,
  setModalState,
  fetchData,
}) => {
  const [levels, setLevels] = useState<Record<string, any>[]>([]);
  const [figures, setFigures] = useState<Record<string, any>[]>([]);

  const [objType, setObjType] = useState<Figure["type"]>("STORED");
  const [objLevel, setObjLevel] = useState<Figure["level"]>("");

  const [objAgg, setObjAgg] = useState<Figure["aggmode"]>("SUM");
  const [objAggTime, setObjAggTime] = useState<Figure["aggtimemode"]>("SUM");

  const [objDisagg, setObjDisagg] = useState<Figure["disaggmode"]>("EQUAL");
  const [objDisaggFigure, setObjDisaggFigure] =
    useState<Figure["disaggfigure"]>();
  const [objDisaggTime, setObjDisaggTime] =
    useState<Figure["disaggtimemode"]>("EQUAL");
  const [objDisaggTimeFigure, setObjDisaggTimeFigure] =
    useState<Figure["disaggtimefigure"]>();

  const [open, setOpen] = useState<boolean>(false);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);
  const ref7 = useRef(null);
  const ref8 = useRef(null);
  const ref9 = useRef(null);
  const ref10 = useRef(null);
  const ref11 = useRef(null);
  const ref12 = useRef(null);

  const [form] = useForm();

  useEffect(() => {
    if (objDisagg !== "FIGURE") {
      setObjDisaggFigure(undefined);
    }
  }, [objDisagg]);

  useEffect(() => {
    if (objDisaggTime !== "FIGURE") {
      setObjDisaggTimeFigure(undefined);
    }
  }, [objDisaggTime]);

  useEffect(() => {
    if (objType !== "CALCULATED") {
      form.setFieldValue("formulacalc", "");
    }
  }, [objType, form]);

  useEffect(() => {
    setObjDisagg("EQUAL");
    setObjDisaggTime("EQUAL");
  }, [objLevel]);

  const fetchLevels = async () => {
    const rawData = await LevelService.getAll(model);
    if (rawData) setLevels(rawData.data);
  };

  const fetchFigures = async () => {
    const response = await FigureService.getAll(model);
    setFigures(response.data);
  };

  useEffect(() => {
    fetchLevels();
    fetchFigures();
  }, []);

  const onFinish = async (e: any) => {
    const toSend = new FigureCreate({
      model,
      id: e.id.toUpperCase(),
      name: e.name,
      description: e.description,
      type: objType,
      level: objLevel,
      aggmode: objAgg,
      aggtimemode: objAggTime,
      disaggmode: objDisagg,
      disaggfigure: objDisaggFigure,
      disaggtimefigure: objDisaggTimeFigure,
      disaggtimemode: objDisaggTime,
      formularequest: e.formularequest,
      formulacalc: e.formulacalc,
    });

    const response = await FigureService.create(toSend); // Когда фиксанут backend, нужно будет изменить

    switch (response.code) {
      case 1109: {
        notification.success({ message: "Показатель успешно создан" });
        setModalState(false);
        fetchData();
        form.resetFields();
        break;
      }
      default: {
        notification.error({ message: response.text });
      }
    }
  };

  const steps: TourProps["steps"] = [
    {
      title: "Введите идентификатор показателя",
      description:
        "Идентификатора показателя может содержать только латинские буквы и цифры.",
      target: () => ref1.current,
    },
    {
      title: "Введите наименование показателя",
      description:
        "Это короткое и понятное наименование показателя, отражающее его суть (например, Выручка | План | BIAS (смещение прогноза)).",
      target: () => ref2.current,
    },
    {
      title: "Введите описание показателя (опционально)",
      description: "Текст в произвольном формате",
      target: () => ref3.current,
    },
    {
      title: "Выберите тип показателя",
      description:
        "Хранимый показатель - значение показателя записывается в базу данных",
      target: () => ref4.current,
    },
    {
      title: "Выберите уровень планирования показателя",
      description: 'Уровни планирования задаются в разделе "Уровни"',
      target: () => ref5.current,
    },
    {
      title: "Введите формулу расчета показателя",
      description:
        'Поле для ввода будет активно если выбран тип показателя "Расчетный"',
      target: () => ref6.current,
    },
    {
      title: "Выберите метод агрегации показателя по атрибутам",
      description: "",
      target: () => ref7.current,
    },
    {
      title: "Выберите метод агрегации показателя по времени",
      description: "",
      target: () => ref8.current,
    },
    {
      title: "Выберите метод дезагрегации показателя по атрибутам",
      description: "",
      target: () => ref9.current,
    },
    {
      title:
        "Выберите показатель пропорционально которому будет выполнена дезаграгация по атрибутам",
      description:
        'Поле для ввода будет активно если выбран метод дезагрегации "По показателю"',
      target: () => ref10.current,
    },
    {
      title: "Выберите метод дезагрегации показателя по времени",
      description: "",
      target: () => ref11.current,
    },
    {
      title:
        "Выберите показатель пропорционально которому будет выполнена дезаграгация по времени",
      description:
        'Поле для ввода будет активно если выбран метод дезагрегации "По показателю"',
      target: () => ref12.current,
    },
  ];

  const index = counter({ start: 1 });

  const possibleFiguresOptions = figures
    .filter((figure) => figure.level.id === objLevel)
    .map((figure) => ({ value: figure.id, label: figure.name }));

  return (
    <div style={{ padding: "15px" }}>
      <ConfigProvider locale={ruRU}>
        <Tour
          open={open}
          onClose={() => setOpen(false)}
          mask={false}
          type="primary"
          steps={steps}
          placement="right"
        />
      </ConfigProvider>
      <Form
        name="basic"
        style={{ display: "flex", flexDirection: "column" }}
        initialValues={{ remember: true }}
        onFinish={(e) => onFinish(e)}
        autoComplete="off"
        layout="vertical"
        form={form}
      >
        <Form.Item
          name="id"
          rules={[
            {
              required: true,
              message: "Пожалуйста, введите идентификатор показателя",
            },
            {
              pattern: /^[A-Z][A-Z0-9]*$/,
              message:
                "Идентификатор должен начинаться с заглавной буквы A-Z и содержать только A-Z и 0-9.",
            },
          ]}
        >
          <div
            style={{ display: "flex", flexDirection: "column", gap: "15px" }}
          >
            <span style={{ fontSize: 18, fontWeight: 700 }}>
              <span style={{ color: "#37AB49", fontWeight: 900 }}>
                Шаг {index()}.
              </span>{" "}
              Введите идентификатор показателя
            </span>
            <div ref={ref1}>
              <Input placeholder="Идентификатор показателя" />
            </div>
          </div>
        </Form.Item>
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: "Пожалуйста, введите наименование показателя",
            },
          ]}
        >
          <div
            style={{ display: "flex", flexDirection: "column", gap: "15px" }}
          >
            <span style={{ fontSize: 18, fontWeight: 700 }}>
              <span style={{ color: "#37AB49", fontWeight: 900 }}>
                Шаг {index()}.
              </span>{" "}
              Введите наименование показателя
            </span>
            <div ref={ref2}>
              <Input placeholder="Наименование показателя" />
            </div>
          </div>
        </Form.Item>
        <Form.Item name="description">
          <div
            style={{ display: "flex", flexDirection: "column", gap: "15px" }}
          >
            <span style={{ fontSize: 18, fontWeight: 700 }}>
              <span style={{ color: "#37AB49", fontWeight: 900 }}>
                Шаг {index()}.
              </span>{" "}
              Введите описание показателя
            </span>
            <div ref={ref3}>
              <Input placeholder="Описание показателя" />
            </div>
          </div>
        </Form.Item>
        <Form.Item name="type">
          <div
            style={{ display: "flex", flexDirection: "column", gap: "15px" }}
          >
            <span style={{ fontSize: 18, fontWeight: 700 }}>
              <span style={{ color: "#37AB49", fontWeight: 900 }}>
                Шаг {index()}.
              </span>{" "}
              Выберите тип показателя
            </span>
            <div ref={ref4}>
              <Select
                placeholder={"Тип показателя"}
                options={FigureType.map((el: any) => ({
                  value: el.id,
                  label: el.label,
                }))}
                value={objType}
                onChange={(el) => setObjType(el)}
                showSearch={true}
                filterOption={(
                  input,
                  option?: { label: string; value: string },
                ) => fuzzyIsIn(input, option?.label ?? "")}
              />
            </div>
          </div>
        </Form.Item>
        <Form.Item name="level">
          <div style={{ display: "flex", flexDirection: "column", gap: 15 }}>
            <span style={{ fontSize: 18, fontWeight: 700 }}>
              <span style={{ color: "#37AB49", fontWeight: 900 }}>
                {" "}
                Шаг {index()}.
              </span>{" "}
              Выберите базовый уровень планирования
            </span>
            <div ref={ref5}>
              <Select
                placeholder="Выберите уровень планирования из выпадающего списка"
                options={levels.map((el: any) => ({
                  value: el.id,
                  label: el.name,
                }))}
                onChange={(el: any) => setObjLevel(el)}
                value={objLevel}
                showSearch={true}
                filterOption={(
                  input,
                  option?: { label: string; value: string },
                ) => fuzzyIsIn(input, option?.label ?? "")}
              />
            </div>
          </div>
        </Form.Item>
        <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
          <span style={{ fontSize: 18, fontWeight: 700 }}>
            <span style={{ color: "#37AB49", fontWeight: 900 }}>
              Шаг {index()}.
            </span>{" "}
            Укажите формулу расчета
          </span>
          <div ref={ref6}>
            <Form.Item name="formulacalc">
              <Input
                placeholder="Пропишите формулу расчета показателя"
                disabled={objType === "STORED"}
              />
            </Form.Item>
          </div>
        </div>
        <Form.Item name="aggmode">
          <div
            style={{ display: "flex", flexDirection: "column", gap: "15px" }}
          >
            <span style={{ fontSize: 18, fontWeight: 700 }}>
              <span style={{ color: "#37AB49", fontWeight: 900 }}>
                Шаг {index()}.
              </span>{" "}
              Агрегация (по атрибутам)
            </span>
            <div ref={ref7}>
              <Select
                placeholder="Выберите метод агрегации"
                options={FigureAggAttrModes.map((mode) => ({
                  value: mode.id,
                  label: mode.label,
                }))}
                onChange={(method) => setObjAgg(method)}
                value={objAgg}
                showSearch={true}
                filterOption={(
                  input,
                  option?: { label: string; value: string },
                ) => fuzzyIsIn(input, option?.label ?? "")}
              />
            </div>
          </div>
        </Form.Item>
        <Form.Item name="aggtimemode">
          <div
            style={{ display: "flex", flexDirection: "column", gap: "15px" }}
          >
            <span style={{ fontSize: 18, fontWeight: 700 }}>
              <span style={{ color: "#37AB49", fontWeight: 900 }}>
                Шаг {index()}.
              </span>{" "}
              Агрегация (по времени)
            </span>
            <div ref={ref8}>
              <Select
                placeholder="Выберите метод агрегации"
                options={FigureAggTimeModes.map((mode) => ({
                  value: mode.id,
                  label: mode.label,
                }))}
                onChange={(method) => setObjAggTime(method)}
                value={objAggTime}
                showSearch={true}
                filterOption={(
                  input,
                  option?: { label: string; value: string },
                ) => fuzzyIsIn(input, option?.label ?? "")}
              />
            </div>
          </div>
        </Form.Item>
        <Form.Item name="disaggmode">
          <div
            style={{ display: "flex", flexDirection: "column", gap: "15px" }}
          >
            <span style={{ fontSize: 18, fontWeight: 700 }}>
              <span style={{ color: "#37AB49", fontWeight: 900 }}>
                Шаг {index()}.
              </span>{" "}
              Дезагрегация (по атрибутам)
            </span>
            <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
              <div ref={ref9} style={{ width: "50%" }}>
                <Select
                  placeholder="Выберите метод дезагрегации"
                  options={FigureDisAgrModes.map((mode) => ({
                    value: mode.id,
                    label: mode.label,
                    disabled: !(
                      mode.id !== "FIGURE" || possibleFiguresOptions.length > 0
                    ),
                  }))}
                  onChange={(el: any) => setObjDisagg(el)}
                  value={objDisagg}
                  showSearch={true}
                  filterOption={(
                    input,
                    option?: { label: string; value: string },
                  ) => fuzzyIsIn(input, option?.label ?? "")}
                />
              </div>
              <span ref={ref10} style={{ width: "50%" }}>
                <Select
                  disabled={
                    !(
                      objDisagg === "FIGURE" &&
                      possibleFiguresOptions.length > 0
                    )
                  }
                  options={possibleFiguresOptions}
                  allowClear
                  onChange={(el: any) => setObjDisaggFigure(el)}
                  value={objDisaggFigure}
                  showSearch={true}
                  filterOption={(
                    input,
                    option?: { label: string; value: string },
                  ) => fuzzyIsIn(input, option?.label ?? "")}
                />
              </span>
            </div>
          </div>
        </Form.Item>
        <Form.Item name="disaggtimemode">
          <div
            style={{ display: "flex", flexDirection: "column", gap: "15px" }}
          >
            <span style={{ fontSize: 18, fontWeight: 700 }}>
              <span style={{ color: "#37AB49", fontWeight: 900 }}>
                Шаг {index()}.
              </span>{" "}
              Дезагрегация (по времени)
            </span>
            <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
              <div ref={ref11} style={{ width: "50%" }}>
                <Select
                  placeholder="Выберите метод дезагрегации"
                  options={FigureDisTimeModes.map((mode) => ({
                    value: mode.id,
                    label: mode.label,
                    disabled: !(
                      mode.id !== "FIGURE" || possibleFiguresOptions.length > 0
                    ),
                  }))}
                  onChange={(el: any) => setObjDisaggTime(el)}
                  value={objDisaggTime}
                  showSearch={true}
                  filterOption={(
                    input,
                    option?: { label: string; value: string },
                  ) => fuzzyIsIn(input, option?.label ?? "")}
                />
              </div>
              <span ref={ref12} style={{ width: "50%" }}>
                <Select
                  disabled={
                    !(
                      objDisaggTime === "FIGURE" &&
                      possibleFiguresOptions.length > 0
                    )
                  }
                  options={possibleFiguresOptions}
                  allowClear
                  onChange={(el: any) => setObjDisaggTimeFigure(el)}
                  value={objDisaggTimeFigure}
                  showSearch={true}
                  filterOption={(
                    input,
                    option?: { label: string; value: string },
                  ) => fuzzyIsIn(input, option?.label ?? "")}
                />
              </span>
            </div>
          </div>
        </Form.Item>
        <Form.Item>
          <div style={{ display: "flex", gap: "10px" }}>
            <Button
              type="primary"
              htmlType="submit"
              style={{ backgroundColor: "#37AB49" }}
            >
              ➕ Создать
            </Button>
            <Button type="dashed" onClick={() => setOpen(true)}>
              🎓 Инструкция
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default FigureCreatePage;
