import { Node } from "@xyflow/react";
import { Input, Select } from "antd";
import { produce } from "immer";
import * as R from "ramda";
import { useContext, useEffect, useState } from "react";
import TransferService from "../../../../../entities/model/TransferService";
import { fuzzyIsIn } from "../../../../../shared/helper/comparison";
import { useICState } from "../../state";
import { Context, SContent } from "../components";
import IntegrationManager from "../utils/common";

type Option = {
  label: string;
  value: string;
};

type Props = {
  id: string;
};

type State = {
  rabbitConn: string;
  rabbitMessage: string;
  rabbitVhost: string;
};

const initialState: State = {
  rabbitConn: "",
  rabbitMessage: "",
  rabbitVhost: "",
};

const RabbitmqOutput: React.FC<Props> = ({ id }) => {
  const { nodes, edges, setNodes } = useICState();
  const { actions } = useContext(Context);

  const [state, setState] = useState<State>(initialState);

  useEffect(() => {
    actions.save = () => {
      setNodes(
        produce((nodes: Node[]) => {
          const node = nodes.find((node) => node.id === id);
          node.data.rabbitConn = state.rabbitConn;
          node.data.rabbitMessage = state.rabbitMessage;
          node.data.rabbitVhost = state.rabbitVhost;
        }),
      );
    };
  });

  useEffect(() => {
    const node = nodes.find((node) => node.id === id);

    if (node) {
      getRabbitConn();

      setState(
        produce((state) => {
          state.rabbitConn = node.data.rabbitConn;
          state.rabbitMessage = node.data.rabbitMessage;
          state.rabbitVhost = node.data.rabbitVhost;
        }),
      );
    }

    IntegrationManager.initial(
      id,
      nodes,
      edges,
      () => {},
      () => {},
    );
  }, [id]);

  const setStateKeyValue =
    (key: string) => (value: any | ((value: any) => any)) => {
      setState(
        produce((state) => {
          state[key] = typeof value === "function" ? value(state[key]) : value;
        }),
      );
    };

  const setRabbitConn = setStateKeyValue("rabbitConn");
  const setRabbitMessage = setStateKeyValue("rabbitMessage");
  const setRabbitVhost = setStateKeyValue("rabbitVhost");

  const [rabbitConnOptions, setRabbitConnOptions] = useState<Option[]>([]);

  const getRabbitConn = async () => {
    const getSystems = await TransferService.getAll({
      filters: [{ id: "system", value: "RABBITMQ" }],
    });
    setRabbitConnOptions(
      getSystems.data.rows.map((el: any) => ({ label: el.id, value: el.id })),
    );
  };

  return (
    <SContent>
      <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>
            Подключение RabbitMQ
          </div>
          <Select
            style={{ width: "300px", color: "black" }}
            value={state.rabbitConn}
            onChange={setRabbitConn}
            showSearch={true}
            options={rabbitConnOptions}
            filterOption={(input, option?: Option) =>
              fuzzyIsIn(input, option?.label ?? "")
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>vHost</div>
          <Input
            value={state.rabbitVhost}
            onChange={R.pipe(R.path(["target", "value"]), setRabbitVhost)}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "150px", fontWeight: "bold" }}>
            Тело сообщения
          </div>
          <Input.TextArea
            value={state.rabbitMessage}
            onChange={R.pipe(R.path(["target", "value"]), setRabbitMessage)}
          />
        </div>
      </div>
    </SContent>
  );
};

export default RabbitmqOutput;
