const colors = {
  mapping: { default: "var(--Primary-600)", hover: "var(--Primary-400)" },
  filter: {
    default: "var(--Secondary-Maroon-600)",
    hover: "var(--Secondary-Maroon-400)",
  },
  join: {
    default: "var(--Secondary-Blue-600)",
    hover: "var(--Secondary-Blue-400)",
  },
  knowledgeSpace: {
    default: "var(--Secondary-Purple-600)",
    hover: "var(--Secondary-Purple-400)",
  },
  rabbitmq: {
    default: "var(--Secondary-Orange-600)",
    hover: "var(--Secondary-Orange-400)",
  },
  rowGen: {
    default: "var(--Secondary-Red-600)",
    hover: "var(--Secondary-Red-400)",
  },
  extrapolation: {
    default: "var(--Secondary-Purple-600)",
    hover: "var(--Secondary-Purple-400)",
  },
  flatFile: {
    default: "var(--Secondary-Green-600)",
    hover: "var(--Secondary-Green-400)",
  },
  group: {
    default: "var(--Secondary-Teal-600)",
    hover: "var(--Secondary-Teal-400)",
  },
  postgres: {
    default: "var(--Secondary-Blue-600)",
    hover: "var(--Secondary-Blue-400)",
  },
  launchFlow: {
    default: "var(--Primary-Grey-600)",
    hover: "var(--Primary-Grey-400)",
  },
  clickhouse: {
    default: "var(--Secondary-Orange-600)",
    hover: "var(--Secondary-Orange-400)",
  },
  planx: {
    default: "var(--Secondary-Red-600)",
    hover: "var(--Secondary-Red-400)",
  },
  note: "#fdd89250",
};

export function nodeColor(node: any) {
  switch (node.type) {
    case "flatFile":
      return colors.flatFile;
    case "targetFlatFile":
      return colors.flatFile;
    case "join":
      return colors.join;
    case "mapping":
      return colors.mapping;
    case "filter":
      return colors.filter;
    case "groupBy":
      return colors.group;
    case "rowGen":
      return colors.rowGen;
    case "knowledgeSpaceClassInput":
      return colors.knowledgeSpace;
    case "knowledgeSpaceClassOutput":
      return colors.knowledgeSpace;
    case "knowledgeSpaceDictionaryInput":
      return colors.knowledgeSpace;
    case "planxInput":
      return colors.planx;
    case "planxFigureOutput":
      return colors.planx;
    case "planxOutput":
      return colors.planx;
    case "postgresInput":
      return colors.postgres;
    case "postgresOutput":
      return colors.postgres;
    case "clickhouseInput":
      return colors.clickhouse;
    case "clickhouseOutput":
      return colors.clickhouse;
    case "note":
      return colors.note;
    case "rabbitmq":
      return colors.rabbitmq;
    case "launchFlow":
      return colors.launchFlow;
    case "extrapolation":
      return colors.extrapolation;
    default:
      return "#ff0072";
  }
}

export default colors;
