import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ConfigProvider, Layout } from "antd";
import { ThemeConfig } from "antd/lib";
import { observer } from "mobx-react-lite";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useAppContext } from "..";
import { Content, Header, Sidebar } from "../pages/layout";
import LoadingSpinner from "../shared/components/loader";
import RoutesList from "../shared/consts/router";

const queryClient = new QueryClient();

const ANTD_THEME_CONFIG: ThemeConfig = {
  token: {
    fontFamily: "Montserrat",
  },
};

const Wrapper = ({ children }: { children: React.ReactNode }) => (
  <ConfigProvider theme={ANTD_THEME_CONFIG}>
    <QueryClientProvider client={queryClient}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <BrowserRouter>{children}</BrowserRouter>
      </LocalizationProvider>
    </QueryClientProvider>
  </ConfigProvider>
);

const App = () => {
  const { store } = useAppContext();

  React.useEffect(() => {
    if (localStorage.getItem("refreshToken")) {
      store.checkAuth().then(() => {
        if (store.isAuth) {
          setInterval(store.backgroundRefresh, 1000 * 60 * 1);
        }
      });
    }
  }, [store]);

  if (store.isLoading) {
    return <LoadingSpinner />;
  }

  if (!store.isAuth) {
    return (
      <Wrapper>
        <Routes>
          <Route path={"*"} element={RoutesList.unAuthorized[0].element} />
        </Routes>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <div className="app">
        <Layout hasSider style={{ background: "none" }}>
          <Sidebar />
          <Layout
            style={{ height: "100vh", overflow: "hidden", background: "none" }}
          >
            <Header />
            <Content>
              <Routes>
                {RoutesList.authorized.map((route, index) => {
                  return (
                    <Route
                      key={index}
                      path={route.path}
                      element={route.element}
                    />
                  );
                })}
              </Routes>
            </Content>
          </Layout>
        </Layout>
      </div>
    </Wrapper>
  );
};

export default observer(App);
