import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Input, Modal, Popconfirm, notification } from "antd";
import { RefObject, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import VersionService from "../../../../entities/model/VersionService";
import { IVersion } from "../../../../entities/types/IVersion";
import VersionModal from "../../../../features/componets/VersionModal";
import Button from "../../../../shared/components/button";
import { Loader } from "../../../../shared/components/loader";
import VersionCreatePage from "../../version/createVersion";

const PageModelVersion: React.FC = () => {
  const params = useParams();
  const modelId: string = params.modelID || "";

  const [versions, setVersions] = useState<IVersion[]>([]);
  const [createModalState, setCreateModalState] = useState<boolean>(false);
  const [listState, setListState] = useState<boolean>(false);
  const [filterText, setFilterText] = useState("");

  const modalRef: RefObject<any> = useRef();

  const openAddModal = async () => {
    setCreateModalState(true);
  };

  const closeAddModal = async () => {
    setCreateModalState(false);
    versionFetch();
  };

  const versionFetch = async () => {
    setListState(true);
    const response = await VersionService.getAll({ modelId });
    setVersions(response.data);
    setListState(false);
  };
  useEffect(() => {
    versionFetch();
  }, []);

  const handleVersionDelete = async (versionId: string) => {
    const response = await VersionService.delete(modelId, versionId);
    notification.warning({
      message: response.text,
      description: `Код ответа: ${response.code}`,
    });
    versionFetch();
  };

  return (
    <>
      <TableContainer>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "15px",
            marginBottom: "15px",
          }}
        >
          <span>Всего версий: {versions ? versions.length : 0}</span>
          <Button label="📁 Добавить" type="primary" onClick={openAddModal} />
          <Button label="🔄 Обновить" type="primary" onClick={versionFetch} />
        </div>
        <Input
          placeholder="Поиск"
          style={{ marginTop: "15px", marginBottom: "15px" }}
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
          allowClear
        />
        {listState && <Loader />}
        {!listState && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "bold" }}>Ид</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Наименование
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Описание</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Действие</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {versions &&
                versions
                  .filter((version) => {
                    const searchText = filterText.toLowerCase();
                    return (
                      version.id.toLowerCase().includes(searchText) ||
                      version.name.toLowerCase().includes(searchText)
                    );
                  })
                  .map((version) => {
                    return (
                      <TableRow key={version.id}>
                        <TableCell>{version.id}</TableCell>
                        <TableCell>{version.name}</TableCell>
                        <TableCell>{version.description}</TableCell>
                        <TableCell>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "10px",
                            }}
                          >
                            <Button
                              label="⚙️ Настройки"
                              type="dashed"
                              size="small"
                              onClick={() =>
                                modalRef.current?.openEditModal(
                                  modelId,
                                  version,
                                )
                              }
                            />
                            <Popconfirm
                              title="Удаление показателя"
                              description={`Вы действительно хотите удалить версию ${version.id}?`}
                              onConfirm={() => handleVersionDelete(version.id)}
                              okText="⚠ Да, удалить"
                              cancelText="Нет, отменить"
                            >
                              <Button
                                label="🚫 Удалить"
                                type="dashed"
                                size="small"
                              />
                            </Popconfirm>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      <Modal
        title="Добавить версию"
        open={createModalState}
        onCancel={() => setCreateModalState(false)}
        width={"855px"}
        footer={[
          <Button
            key="cancel"
            onClick={closeAddModal}
            type="dashed"
            label="Отменить"
          />,
        ]}
      >
        <VersionCreatePage
          modelId={modelId}
          modalState={setCreateModalState}
          dataFetch={versionFetch}
        />
      </Modal>
      <VersionModal
        modelId={modelId}
        apiRef={modalRef}
        fetchData={versionFetch}
      />
    </>
  );
};

export default PageModelVersion;
