import styled from "@emotion/styled";
import { useQuery } from "@tanstack/react-query";
import { Popover } from "antd";
import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppContext } from "../..";
import { ReactComponent as UserRound } from "../../assets/user_round.svg";
import UserService from "../../entities/model/UserService";
import { Loader } from "../../shared/components/loader";
import { links } from "./constants";

import { ReactComponent as ArrowRight } from "../../assets/arrow_right.svg";
import { ReactComponent as ExitToApp } from "../../assets/exit_to_app.svg";
import { ReactComponent as ExpandMore } from "../../assets/expand_more.svg";
import { ReactComponent as Home } from "../../assets/home.svg";
import { ReactComponent as Setting } from "../../assets/setting.svg";

const SHeaderContainer = styled.div({
  padding: "16px 24px 0px 0px",
  background: "none",
});

const SHeader = styled.div({
  borderRadius: "8px",
  backgroundColor: "var(--Primary-Grey-010)",
});

const SHeaderBody = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  height: "48px",
  backgroundColor: "var(--Primary-Grey-005)",
  borderRadius: "8px",
});

const SHeaderBreadcrumbs = styled.div({
  height: "28px",
  padding: "2px 10px",
});

const SHeaderTitle = styled.h1({
  fontWeight: "700",
  fontSize: "14px",
  color: "var(--Primary-Grey-400)",
  textTransform: "uppercase",
  margin: "0",
  textAlign: "center",
  padding: "16px",
});

const SHeaderUser = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "10px",
  padding: "4px 8px",
  borderRadius: "4px",
  cursor: "pointer",
  marginRight: "8px",
  ":hover": {
    backgroundColor: "var(--Primary-400)",
    "*": {
      color: "var(--White)",
      fill: "var(--White)",
    },
  },
});

const SHeaderUserName = styled.div({
  display: "flex",
  alignItems: "center",
  color: "var(--Primary-Grey-200)",
});

const SHeaderUserAvatar = styled.div({
  display: "flex",
  alignItems: "center",
  width: "24px",
  height: "24px",
});

const SBreadcrumbContainer = styled.div(
  ({ isActive = false }: { isActive?: boolean }) =>
    ({
      height: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyItems: "center",
      padding: "5px 4px 5px 8px",
      borderRadius: "4px",
      gap: "2px",
      cursor: "default",
      ":hover": {
        backgroundColor: isActive ? undefined : "var(--White)",
        "*": {
          color: isActive ? undefined : `var(--Black)`,
        },
      },
    }) as const,
);

const SBreadcrumbTitle = styled.div(
  ({ isActive = false }: { isActive?: boolean }) =>
    ({
      color: isActive ? "var(--Primary-600)" : "var(--Primary-Grey-400)",
      fontSize: "12px",
      fontWeight: "400",
      lineHeight: "0",
    }) as const,
);

const SBreadcrumbIcon = styled.div(
  ({ isActive = false }: { isActive?: boolean }) =>
    ({
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      "*": {
        fill: isActive ? "var(--Primary-400)" : "var(--Primary-Grey-100)",
        width: isActive ? "18px" : "16px",
        height: isActive ? "18px" : "16px",
      },
    }) as const,
);

const Breadcrumb = ({
  children,
  isActive = false,
}: {
  children: React.ReactNode;
  isActive?: boolean;
}) => {
  const icon = isActive ? <ExpandMore /> : <ArrowRight />;

  return (
    <SBreadcrumbContainer isActive={isActive}>
      <SBreadcrumbTitle isActive={isActive}>{children}</SBreadcrumbTitle>
      <SBreadcrumbIcon isActive={isActive}>{icon}</SBreadcrumbIcon>
    </SBreadcrumbContainer>
  );
};

const SBreadcrumbs = styled.div({
  display: "flex",
  flexDirection: "row",
  gap: "0px",
  height: "100%",
});

const Breadcrumbs = ({ children }: { children: React.ReactNode }) => {
  return <SBreadcrumbs>{children}</SBreadcrumbs>;
};

const SBreadcrumbIconItem = styled.div({
  width: "24px",
  height: "24px",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "4px",
  cursor: "pointer",
  ":hover": {
    backgroundColor: "var(--White)",
    "*": {
      fill: "var(--Primary-Grey-400)",
    },
  },
});

const SUserProfileContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  padding: "8px 8px 16px 8px",
  gap: "8px",
});

const SUserProfileHeader = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  height: "32px",
  borderBottom: "1px solid var(--Primary-Grey-050)",
  color: "var(--Primary-Grey-400)",
  fontSize: "18px",
  fontWeight: "500",
  lineHeight: "24px",
  padding: "0px 8px 8px 8px",
});

const SUserProfileUser = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  height: "64px",
  padding: "8px",
  gap: "16px",
});

const SUserProfileUserIcon = styled.div({
  height: "48px",
  width: "48px",
});

const SUserProfileUserInfo = styled.div({
  display: "flex",
  flexDirection: "column",
  padding: "4px 0px",
  gap: "8px",
  height: "48px",
});

const SUserProfileUserInfoName = styled.div({
  fontWeight: "500",
  fontSize: "14px",
  color: "var(--Primary-Grey-400)",
});

const SUserProfileUserInfoEmail = styled.div({
  fontWeight: "400",
  fontSize: "12px",
  color: "var(--Primary-400)",
  cursor: "pointer",
});

const SUserProfileFooter = styled.div({
  display: "flex",
  flexDirection: "row",
  gap: "8px",
  height: "24px",
  justifyContent: "space-around",
});

const SButton = styled("button")(({
  size = "s",
  kind = "default",
}: {
  size?: "s";
  kind?: "default" | "outlined";
}): any => {
  const styles: any = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "4px",
    cursor: "pointer",
    fontSize: "14px",
    fontWeight: "400",
    padding: "4px 0",
    gap: "4px",
    "*": {
      opacity: "70%",
    },
    ":hover": {
      "*": {},
    },
    ":active": {
      backgroundColor: "var(--Primary-800)",
    },
  };

  if (size === "s") {
    styles.minWidth = "124px";
  }

  if (kind === "default") {
    styles.backgroundColor = "var(--Primary-400)";
    styles.border = "none";
    styles.color = "var(--White)";
    styles["*"].fill = "var(--White)";

    styles[":hover"].backgroundColor = "var(--Primary-700)";
    styles[":hover"].color = "var(--White)";
    styles[":hover"]["*"].fill = "var(--White)";
  }

  if (kind === "outlined") {
    styles.background = "none";
    styles.border = "1px solid var(--Primary-400)";
    styles.color = "var(--Primary-400)";
    styles["*"].fill = "var(--Primary-400)";

    styles[":hover"].backgroundColor = "var(--Primary-400)";
    styles[":hover"].border = "none";
    styles[":hover"].color = "var(--White)";
    styles[":hover"]["*"].fill = "var(--White)";
  }

  return styles;
});

const UserProfile = ({
  icon,
  name,
  surname,
  email,
  onSignOut,
  onSettings,
}: any) => {
  return (
    <SUserProfileContainer>
      <SUserProfileHeader>Аккаунт</SUserProfileHeader>
      <SUserProfileUser>
        <SUserProfileUserIcon>{icon}</SUserProfileUserIcon>
        <SUserProfileUserInfo>
          <SUserProfileUserInfoName>
            {name} {surname}
          </SUserProfileUserInfoName>
          <SUserProfileUserInfoEmail>
            <CopyToClipboard text={email}>
              <span>{email}</span>
            </CopyToClipboard>
          </SUserProfileUserInfoEmail>
        </SUserProfileUserInfo>
      </SUserProfileUser>
      <SUserProfileFooter>
        <SButton kind="outlined" size="s" onClick={onSignOut}>
          Выход
          <ExitToApp width="16px" height="16px" />
        </SButton>
        <SButton kind="default" size="s" onClick={onSettings}>
          Настройки
          <Setting width="16px" height="16px" />
        </SButton>
      </SUserProfileFooter>
    </SUserProfileContainer>
  );
};

export const Header = () => {
  const { store } = useAppContext();
  const location = useLocation();
  const navigate = useNavigate();

  const [title, setTitle] = useState<string>("");

  const breadcrumbs = links.find((link) =>
    location.pathname.startsWith(link.link),
  )?.breadcrumbs;

  const { data: avatar, isLoading: isAvatarLoading } = useQuery<
    string | undefined
  >({
    queryKey: ["user-avatar"],
    refetchInterval: false,
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const response = await UserService.getAvatar();
      return response.data?.data?.avatar;
    },
  });

  useEffect(() => {
    const path = location.pathname;
    const item = links.find((link) => link.link === path)?.label;
    setTitle(item ?? store.pageName);
  }, [store.pageName, location.pathname]);

  const avatarElement = (() => {
    if (isAvatarLoading) return <Loader />;
    return typeof avatar === "undefined" ? (
      <UserRound width="100%" height="100%" />
    ) : (
      <img
        alt="avatar"
        src={avatar}
        height="100%"
        width="100%"
        style={{ borderRadius: "50%" }}
      />
    );
  })();

  return (
    <SHeaderContainer>
      <SHeader>
        <SHeaderBody>
          <SHeaderTitle>{title}</SHeaderTitle>
          <Popover
            trigger="click"
            content={
              <UserProfile
                icon={avatarElement}
                name={store.user.name}
                surname={store.user.surname}
                email={store.user.email}
                onSettings={() => navigate("/settings")}
                onSignOut={() => {
                  store.signout();
                  navigate("/");
                }}
              />
            }
            placement="bottomLeft"
            overlayStyle={{
              margin: "0",
              padding: "0",
              minHeight: "160px",
              minWidth: "288px",
            }}
            overlayInnerStyle={{ margin: "0", padding: "0" }}
          >
            <SHeaderUser>
              <SHeaderUserName>
                {store.user.name} {store.user.surname} @
              </SHeaderUserName>
              <SHeaderUserAvatar>{avatarElement}</SHeaderUserAvatar>
            </SHeaderUser>
          </Popover>
        </SHeaderBody>
        {!(breadcrumbs === undefined || breadcrumbs.length === 0) && (
          <SHeaderBreadcrumbs>
            <Breadcrumbs>
              <Link to="/">
                <SBreadcrumbIconItem>
                  <Home width="16" height="16" />
                </SBreadcrumbIconItem>
              </Link>
              {breadcrumbs.map((breadcrumb, i) => (
                <Breadcrumb key={i} isActive={i === breadcrumbs.length - 1}>
                  {breadcrumb}
                </Breadcrumb>
              ))}
            </Breadcrumbs>
          </SHeaderBreadcrumbs>
        )}
      </SHeader>
    </SHeaderContainer>
  );
};

export default Header;
