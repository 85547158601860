import styled from "@emotion/styled";
import { Input } from "@mui/material";
import React, { Fragment, useState } from "react";

import { ReactComponent as AddIcon } from "../../../assets/add.svg";
import { ReactComponent as CheckSolidIcon } from "../../../assets/check_solid.svg";
import { ReactComponent as ClickhouseIcon } from "../../../assets/clickhouse.svg";
import { ReactComponent as ConnectionIcon } from "../../../assets/connection.svg";
import { ReactComponent as DocumentIcon } from "../../../assets/document.svg";
import { ReactComponent as DoneIcon } from "../../../assets/done.svg";
import { ReactComponent as DownloadIcon } from "../../../assets/download.svg";
import { ReactComponent as ExpandLessIcon } from "../../../assets/expand_less.svg";
import { ReactComponent as ExpandMoreIcon } from "../../../assets/expand_more.svg";
import { ReactComponent as FilterSquareIcon } from "../../../assets/filter_square.svg";
import { ReactComponent as ForecastIcon } from "../../../assets/forecast.svg";
import { ReactComponent as GenerateIcon } from "../../../assets/generate.svg";
import { ReactComponent as KSIcon } from "../../../assets/ks.svg";
import { ReactComponent as NotesIcon } from "../../../assets/notes.svg";
import { ReactComponent as PostgresqlIcon } from "../../../assets/postgresql.svg";
import { ReactComponent as PXIcon } from "../../../assets/px.svg";
import { ReactComponent as RabbitMQIcon } from "../../../assets/rabbitmq.svg";
import { ReactComponent as UnitRegionIcon } from "../../../assets/unit_region.svg";

import { useICLibraryStore } from "./state";

function item(
  name: string,
  type: string,
  icon: React.ReactNode,
  colors: [string, string],
) {
  return { name, type, icon, colors };
}

function group(name: string, items: ReturnType<typeof item>[]) {
  return { name, items, isOpen: false } as {
    name: string;
    items: ReturnType<typeof item>[];
    isOpen: boolean;
  };
}

const LIBRARY = [
  group("Заметки", [
    item("Заметка", "note", <NotesIcon />, [
      "var(--Primary-Grey-200)",
      "var(--Primary-Grey-400)",
    ]),
  ]),

  group("Трансформации", [
    item("Выбор", "mapping", <CheckSolidIcon />, [
      "var(--Primary-400)",
      "var(--Primary-600)",
    ]),
    item("Фильтр", "filter", <FilterSquareIcon />, [
      "var(--Secondary-Maroon-400)",
      "var(--Secondary-Maroon-600)",
    ]),
    item("Группировка", "groupBy", <UnitRegionIcon />, [
      "var(--Secondary-Teal-400)",
      "var(--Secondary-Teal-600)",
    ]),
    item("Соединение", "join", <ConnectionIcon />, [
      "var(--Secondary-Blue-400)",
      "var(--Secondary-Blue-600)",
    ]),
    item("Генератор", "rowGen", <GenerateIcon />, [
      "var(--Secondary-Red-400)",
      "var(--Secondary-Red-600)",
    ]),
    item("Прогноз", "extrapolation", <ForecastIcon />, [
      "var(--Secondary-Maroon-400)",
      "var(--Secondary-Maroon-600)",
    ]),
  ]),

  group("Источники", [
    item("PX Показатели", "planxFigureInput", <PXIcon />, [
      "var(--Secondary-Red-400)",
      "var(--Secondary-Red-600)",
    ]),
    item("Плоский файл", "flatFile", <DocumentIcon />, [
      "var(--Secondary-Green-400)",
      "var(--Secondary-Green-600)",
    ]),
    item("KS Словарь", "knowledgeSpaceDictionaryInput", <KSIcon />, [
      "var(--Secondary-Purple-400)",
      "var(--Secondary-Purple-600)",
    ]),
    item("KS Класс", "knowledgeSpaceClassInput", <KSIcon />, [
      "var(--Secondary-Purple-400)",
      "var(--Secondary-Purple-600)",
    ]),
    item("PostgreSQL", "postgresInput", <PostgresqlIcon />, [
      "var(--Secondary-Blue-400)",
      "var(--Secondary-Blue-600)",
    ]),
    item("Clickhouse", "clickhouseInput", <ClickhouseIcon />, [
      "var(--Secondary-Orange-400)",
      "var(--Secondary-Orange-600)",
    ]),
  ]),

  group("Цели", [
    item("PX Показатели", "planxFigureOutput", <PXIcon />, [
      "var(--Secondary-Red-400)",
      "var(--Secondary-Red-600)",
    ]),
    item("Плоский файл", "targetFlatFile", <DocumentIcon />, [
      "var(--Secondary-Green-400)",
      "var(--Secondary-Green-600)",
    ]),
    item("KS Класс", "knowledgeSpaceClassOutput", <KSIcon />, [
      "var(--Secondary-Purple-400)",
      "var(--Secondary-Purple-600)",
    ]),
    item("PostgreSQL", "postgresOutput", <PostgresqlIcon />, [
      "var(--Secondary-Blue-400)",
      "var(--Secondary-Blue-600)",
    ]),
  ]),

  group("События", [
    item("RabbitMQ", "rabbitmqOutput", <RabbitMQIcon />, [
      "var(--Secondary-Orange-400)",
      "var(--Secondary-Orange-600)",
    ]),
    item("Launcher", "launchFlow", <DownloadIcon />, [
      "var(--Primary-Grey-200)",
      "var(--Primary-Grey-400)",
    ]),
  ]),
];

const SLibraryContainer = styled.div(
  ({ isOpen = true }: { isOpen?: boolean }) =>
    ({
      backgroundColor: "var(--White)",
      height: "100%",
      borderRadius: "8px",
      overflowY: "hidden",
      minWidth: isOpen ? "220px" : undefined,
      width: isOpen ? undefined : "0px",
      display: isOpen ? undefined : "none",
    }) as const,
);

const SLibraryHeader = styled.div({
  height: "70px",
  padding: "12px 16px 8px 16px",
});

const SLibraryBody = styled.div({
  height: "calc(100% - 70px)",
  overflowY: "auto",
});

const SLibraryHeaderTitle = styled.h1({
  color: "var(--Black)",
  fontWeight: "600",
  fontSize: "12px",
  lineHeight: "18px",
  textTransform: "uppercase",
  margin: "0",
  pointerEvents: "none",
});

const SLibraryRowContainer = styled.div({
  padding: "4px 16px",
  cursor: "pointer",
});

const SLibraryGroup = styled.div(
  ({ isOpen = false }: { isOpen?: boolean }) =>
    ({
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      backgroundColor: isOpen ? undefined : "var(--Primary-Grey-010)",
      borderRadius: "2px",
      padding: "8px 16px",
      minWidth: "188px",
    }) as const,
);

const SLibraryGroupName = styled.div(
  ({ isOpen = false }: { isOpen?: boolean }) =>
    ({
      fontSize: "14px",
      lineHeight: "18px",
      fontWeight: "400",
      color: isOpen ? "var(--Black)" : "var(--Primary-Grey-200)",
    }) as const,
);

const SLibraryGroupIcon = styled.div({
  width: "18px",
  height: "18px",
  "*": {
    width: "100%",
    height: "100%",
  },
});

const LibraryGroupIcon = ({ isOpen = false }: { isOpen?: boolean }) => {
  const Component = isOpen ? ExpandLessIcon : ExpandMoreIcon;

  return (
    <SLibraryGroupIcon>
      <Component width={18} height={18} />
    </SLibraryGroupIcon>
  );
};

const SLibraryGroupItemBodyIcon = styled.div(
  ({ color }: { color?: string }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "18px",
    height: "18px",
    "*": {
      fill: color,
      width: "100%",
      height: "100%",
    },
  }),
);

const SLibraryGroupItemBodyName = styled.div({
  fontWeight: "400",
  fontSize: "14px",
  color: "var(--Primary-Grey-200)",
  lineHeight: "18px",
});

const SLibraryGroupItemBodyHeader = styled.div({
  display: "flex",
  flexDirection: "row",
  gap: "4px",
});

const SLibraryGroupItems = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
});

const SLibraryGroupItemButton = styled.div({
  justifySelf: "end",
  width: "18px",
  height: "18px",
  position: "relative",
  "*": {
    width: "100%",
    height: "100%",
  },
});

const SLibraryGroupItemBody = styled.div({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  height: "100%",
  padding: "8px 16px",
  justifyContent: "space-between",
  position: "relative",
  pointerEvents: "none",
});

const SLibraryGroupItemBackground = styled.div(
  ({ colors }: { colors: [string, string] }) =>
    ({
      position: "absolute",

      width: "100%",
      height: "100%",

      borderRadius: "2px",
      backgroundColor: colors[0],
      overflow: "hidden",

      ":hover >:nth-child(1)": {
        backgroundColor: colors[0],
      },

      ":active": {
        backgroundColor: colors[1],
      },

      ":active >:nth-child(1)": {
        backgroundColor: colors[1],
      },
    }) as const,
);

const SLibraryGroupItemBackgroundCover = styled.div({
  position: "relative",
  marginLeft: "2px",
  width: "100%",
  height: "100%",
  backgroundColor: "var(--White)",
});

const SLibraryGroupItemContainer = styled.div({
  position: "relative",
  minWidth: "188px",
  width: "100%",
  height: "100%",
  cursor: "pointer",

  // place icons in the same position
  ">:nth-child(2) >:nth-child(2) > *": {
    position: "absolute",
  },

  // hide done icon by default
  ">:nth-child(2) >:nth-child(2) >:nth-child(2)": {
    visibility: "hidden",
  },

  // when hover: set icons and text colors for all children
  ":hover *": {
    color: "var(--White)",
    fill: "var(--White)",
  },

  // when hover: set header icons opacity
  ":hover >:nth-child(2) >:nth-child(1) svg": {
    opacity: "0.5",
  },

  // when active: show done icon and hide add icon
  ":active >:nth-child(2) >:nth-child(2) >:nth-child(1)": {
    zIndex: 0,
    visibility: "hidden",
  },
  ":active >:nth-child(2) >:nth-child(2) >:nth-child(2)": {
    zIndex: 10,
    visibility: "visible",
  },
});

const LibraryGroupItem = ({
  name,
  type,
  icon,
  colors,
}: {
  name: string;
  type: string;
  icon: React.ReactNode;
  colors: [string, string];
}) => {
  const onDragStart = React.useCallback(
    (event: any) => {
      event.dataTransfer.setData("application/reactflow", type);
      event.dataTransfer.effectAllowed = "move";
    },
    [type],
  );

  return (
    <SLibraryGroupItemContainer onDragStart={onDragStart} draggable>
      <SLibraryGroupItemBackground colors={colors}>
        <SLibraryGroupItemBackgroundCover />
      </SLibraryGroupItemBackground>

      <SLibraryGroupItemBody>
        <SLibraryGroupItemBodyHeader>
          <SLibraryGroupItemBodyIcon color={colors[0]}>
            {icon}
          </SLibraryGroupItemBodyIcon>
          <SLibraryGroupItemBodyName>{name}</SLibraryGroupItemBodyName>
        </SLibraryGroupItemBodyHeader>
        <SLibraryGroupItemButton>
          <AddIcon />
          <DoneIcon />
        </SLibraryGroupItemButton>
      </SLibraryGroupItemBody>
    </SLibraryGroupItemContainer>
  );
};

const SSeparator = styled.div({
  width: "100%",
  padding: "8px 0",
});

const SSeparatorBar = styled.div({
  backgroundColor: "var(--Primary-Grey-025)",
  height: "1px",
  width: "100%",
});

const Separator = () => {
  return (
    <SSeparator>
      <SSeparatorBar />
    </SSeparator>
  );
};

export const Library = () => {
  const [library, setLibrary] = useState(LIBRARY);
  const isOpen = useICLibraryStore((state) => state.isOpen);

  return (
    <SLibraryContainer isOpen={isOpen}>
      <SLibraryHeader>
        <SLibraryHeaderTitle>Библиотека нод</SLibraryHeaderTitle>
        <Input />
      </SLibraryHeader>

      <Separator />

      <SLibraryBody>
        {library.map((group) => (
          <Fragment key={group.name}>
            <SLibraryRowContainer>
              <SLibraryGroup
                isOpen={group.isOpen}
                onClick={() =>
                  setLibrary((library) =>
                    library.map((x) =>
                      group.name === x.name
                        ? { ...group, isOpen: !group.isOpen }
                        : x,
                    ),
                  )
                }
              >
                <SLibraryGroupName isOpen={group.isOpen}>
                  {group.name}
                </SLibraryGroupName>
                <LibraryGroupIcon isOpen={group.isOpen} />
              </SLibraryGroup>
              {group.isOpen && (
                <SLibraryGroupItems>
                  {group.items.map((item) => (
                    <LibraryGroupItem {...item} />
                  ))}
                </SLibraryGroupItems>
              )}
            </SLibraryRowContainer>
            <Separator />
          </Fragment>
        ))}
      </SLibraryBody>
    </SLibraryContainer>
  );
};
