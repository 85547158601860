import styled from "@emotion/styled";
import { NodeResizer, Position, useNodeId, useReactFlow } from "@xyflow/react";
import { ColorPicker, Divider, Input, message } from "antd";
import React, { useCallback } from "react";
import colors from "./colors";
import Connector from "./connector";

import { ReactComponent as CheckSolidIcon } from "../../../assets/check_solid.svg";
import { ReactComponent as ClickhouseIcon } from "../../../assets/clickhouse.svg";
import { ReactComponent as ConnectionIcon } from "../../../assets/connection.svg";
import { ReactComponent as DocumentIcon } from "../../../assets/document.svg";
import { ReactComponent as DownloadIcon } from "../../../assets/download.svg";
import { ReactComponent as FilterSquareIcon } from "../../../assets/filter_square.svg";
import { ReactComponent as ForecastIcon } from "../../../assets/forecast.svg";
import { ReactComponent as GenerateIcon } from "../../../assets/generate.svg";
import { ReactComponent as KSIcon } from "../../../assets/ks.svg";
import { ReactComponent as PostgresqlIcon } from "../../../assets/postgresql.svg";
import { ReactComponent as PXIcon } from "../../../assets/px.svg";
import { ReactComponent as RabbitMQIcon } from "../../../assets/rabbitmq.svg";
import { ReactComponent as UnitRegionIcon } from "../../../assets/unit_region.svg";

import { ReactComponent as DeleteForeverIcon } from "../../../assets/delete_forever.svg";
import { ReactComponent as EyeIcon } from "../../../assets/eye.svg";
import { useHistory, useICState } from "./state";

const basicElementStyle: any = {
  display: "flex",
  flexDirection: "column",
  zIndex: 2,

  height: "60px",
  width: "240px",

  gap: "7px",

  borderRadius: "8px",

  color: "white",
};

const SNodeContainer = styled.div(
  ({ color }: { color: { default: string; hover: string } }) =>
    ({
      width: "264px",
      height: "74px",
      borderRadius: "8px",
      backgroundColor: color.default,
      padding: "6px 8px",
      display: "flex",
      flexDirection: "column",
      gap: "6px",
      zIndex: "1000 !important",
      boxShadow: "0px 1px 0px 0px rgba(0, 0, 0, 0.08)",

      ":hover": {
        boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.16)",
        backgroundColor: color.hover,
      },
    }) as const,
);

const SNodeHeader = styled.div(
  () =>
    ({
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      height: "18px",
      width: "100%",
    }) as const,
);

const SNodeHeaderTop = styled.div(
  () =>
    ({
      display: "flex",
      flexDirection: "row",
      gap: "4px",
      alignItems: "center",
      justifyContent: "flex-start",
    }) as const,
);

const SNodeHeaderTopIcon = styled.div(
  () =>
    ({
      width: "18px",
      height: "18px",
      opacity: 0.5,
      "*": {
        width: "100%",
        height: "100%",
        fill: "var(--White)",
      },
    }) as const,
);

const SNodeHeaderTopName = styled.div(
  () =>
    ({
      fontWeight: "500",
      fontSize: "12px",
      lineHeight: "18px",
      color: "var(--White)",
    }) as const,
);

const SNodeHeaderBottom = styled.div(
  () =>
    ({
      display: "flex",
      flexDirection: "row",
      gap: "4px",
    }) as const,
);

const SNodeHeaderBottomIcon = styled.div(
  () =>
    ({
      width: "18px",
      height: "18px",
      opacity: 0.5,

      ":hover": {
        opacity: 1,
      },

      "*": {
        width: "100%",
        height: "100%",
        fill: "var(--White)",
      },
    }) as const,
);

const SNodeSeparator = styled.div(
  () =>
    ({
      width: "100%",
      height: "1px",
      opacity: 0.3,
      borderTop: "1px dashed var(--Primary-Grey-050)",
    }) as const,
);

const SNodeBody = styled.div(
  () =>
    ({
      display: "flex",
      flexDirection: "row",
      height: "100%",
      width: "100%",
      gap: "4px",
      alignItems: "center",
      justifyContent: "space-between",
    }) as const,
);

const SNodeIdentifier = styled.div(
  () =>
    ({
      margin: "auto",
      color: "var(--White)",
      width: "200px",
      textOverflow: "ellipsis",
      textWrap: "nowrap",
      overflow: "hidden",
    }) as const,
);

type Settings = {
  nodeName: string;
  nodeBusinessName: string;
  color: { default: string; hover: string };

  icon: React.ReactNode;

  inputs: number;
  outputs: number;

  data: any;
};

const Type_Node = (settings: Settings) => {
  const { openModal } = useICState();

  const { historyRecord } = useHistory();

  const { getNode, setNodes, setEdges, getNodes, getEdges } = useReactFlow();

  const id = useNodeId()!;
  const node = getNode(id)!;

  const onView = useCallback(
    () => openModal(node.id, node.type),
    [openModal, node.id, node.type],
  );

  const onDelete = useCallback(() => {
    if (id === "0") {
      return void message.error("Нельзя удалить корневую ноду");
    }

    setNodes((nodes) => nodes.filter((node) => node.id !== id));
    setEdges((edges) =>
      edges.filter((edge) => edge.source !== id && edge.target !== id),
    );
    historyRecord({
      node: getNodes(),
      edges: getEdges(),
      comment: "onNodeDelete",
    });

    message.warning(`Нода ${id} удалена`);
  }, [id, setNodes, setEdges, historyRecord, getNodes, getEdges]);

  return (
    <SNodeContainer color={settings.color as any}>
      <SNodeHeader>
        <SNodeHeaderTop>
          <SNodeHeaderTopIcon>{settings.icon}</SNodeHeaderTopIcon>
          <SNodeHeaderTopName>{settings.nodeBusinessName}</SNodeHeaderTopName>
        </SNodeHeaderTop>
        <SNodeHeaderBottom>
          <SNodeHeaderBottomIcon onClick={onDelete}>
            <DeleteForeverIcon />
          </SNodeHeaderBottomIcon>
          <SNodeHeaderBottomIcon onClick={onView}>
            <EyeIcon />
          </SNodeHeaderBottomIcon>
        </SNodeHeaderBottom>
      </SNodeHeader>
      <SNodeSeparator />
      <SNodeBody>
        <Connector
          id={`${settings.nodeName}_input`}
          type="target"
          position={Position.Left}
          maxConnections={settings.inputs}
        />
        <SNodeIdentifier>{settings.data.label}</SNodeIdentifier>
        <Connector
          id={`${settings.nodeName}_output`}
          type="source"
          position={Position.Right}
          maxConnections={settings.outputs}
        />
      </SNodeBody>
    </SNodeContainer>
  );
};

export const Type_Mapping = ({ data }: any) => {
  return Type_Node({
    nodeName: "Mapping",
    nodeBusinessName: "Выбор",
    icon: <CheckSolidIcon />,
    color: colors.mapping,
    inputs: 1,
    outputs: 1,
    data,
  });
};

export const Type_GroupBy = ({ data }: any) => {
  return Type_Node({
    nodeName: "Group",
    nodeBusinessName: "Группировка",
    icon: <UnitRegionIcon />,
    color: colors.group,
    inputs: 1,
    outputs: 1,
    data,
  });
};

export const Type_Join = ({ data }: any) => {
  return Type_Node({
    nodeName: "Join",
    nodeBusinessName: "Соединение",
    icon: <ConnectionIcon />,
    color: colors.join,
    inputs: 2,
    outputs: 1,
    data,
  });
};

export const Type_RowGen = ({ data }: any) => {
  return Type_Node({
    nodeName: "Generator",
    nodeBusinessName: "Генератор",
    icon: <GenerateIcon />,
    color: colors.rowGen,
    inputs: 1,
    outputs: 1,
    data,
  });
};

export const Type_Extrapolation = ({ data }: any) => {
  return Type_Node({
    nodeName: "Forecast",
    nodeBusinessName: "Прогноз",
    icon: <ForecastIcon />,
    color: colors.extrapolation,
    inputs: 1,
    outputs: 1,
    data,
  });
};

export const Type_Filter = ({ data }: any) => {
  return Type_Node({
    nodeName: "Filter",
    nodeBusinessName: "Фильтр",
    icon: <FilterSquareIcon />,
    color: colors.filter,
    inputs: 1,
    outputs: 1,
    data,
  });
};

export const Type_FlatFile = ({ data }: any) => {
  return Type_Node({
    nodeName: "FlatFileInput",
    nodeBusinessName: "Плоский файл: вход",
    icon: <DocumentIcon />,
    color: colors.flatFile,
    inputs: 1,
    outputs: 1,
    data,
  });
};

export const Type_KnowledgeSpaceClassInput = ({ data }: any) => {
  return Type_Node({
    nodeName: "KSClassInput",
    nodeBusinessName: "KS Класс: вход",
    icon: <KSIcon />,
    color: colors.knowledgeSpace,
    inputs: 1,
    outputs: 1,
    data,
  });
};

export const Type_KnowledgeSpaceDictionaryInput = ({ data }: any) => {
  return Type_Node({
    nodeName: "KSDictionaryInput",
    nodeBusinessName: "KS Словарь: вход",
    icon: <KSIcon />,
    color: colors.knowledgeSpace,
    inputs: 1,
    outputs: 1,
    data,
  });
};

export const Type_PostgresInput = ({ data }: any) => {
  return Type_Node({
    nodeName: "PostgresInput",
    nodeBusinessName: "Postgres: вход",
    icon: <PostgresqlIcon />,
    color: colors.postgres,
    inputs: 1,
    outputs: 1,
    data,
  });
};

export const Type_ClickhouseInput = ({ data }: any) => {
  return Type_Node({
    nodeName: "ClickhouseInput",
    nodeBusinessName: "Clickhouse: вход",
    icon: <ClickhouseIcon />,
    color: colors.clickhouse,
    inputs: 1,
    outputs: 1,
    data,
  });
};

export const Type_TargetFlatFile = ({ data }: any) => {
  return Type_Node({
    nodeName: "FlatFileOutput",
    nodeBusinessName: "Плоский файл: выход",
    icon: <DocumentIcon />,
    color: colors.flatFile,
    inputs: 1,
    outputs: 1,
    data,
  });
};

export const Type_KnowledgeSpaceClassOutput = ({ data }: any) => {
  return Type_Node({
    nodeName: "KSClassOutput",
    nodeBusinessName: "KS Класс: выход",
    icon: <KSIcon />,
    color: colors.knowledgeSpace,
    inputs: 1,
    outputs: 1,
    data,
  });
};

export const Type_PlanXInput = ({ data }: any) => {
  return Type_Node({
    nodeName: "PXInput",
    nodeBusinessName: "Planx: вход",
    icon: <PXIcon />,
    color: colors.planx,
    inputs: 1,
    outputs: 1,
    data,
  });
};

export const Type_PlanXOutput = ({ data }: any) => {
  return Type_Node({
    nodeName: "PXOutput",
    nodeBusinessName: "Planx: выход",
    icon: <PXIcon />,
    color: colors.planx,
    inputs: 1,
    outputs: 1,
    data,
  });
};

export const Type_PostgresOutput = ({ data }: any) => {
  return Type_Node({
    nodeName: "PostgresOutput",
    nodeBusinessName: "Postgres: выход",
    icon: <PostgresqlIcon />,
    color: colors.postgres,
    inputs: 1,
    outputs: 1,

    data,
  });
};

export const Type_RabbitOutput = ({ data }: any) => {
  return Type_Node({
    nodeName: "RabbitMQOutput",
    nodeBusinessName: "RabbitMQ: выход",
    icon: <RabbitMQIcon />,
    color: colors.rabbitmq,
    inputs: 1,
    outputs: 1,
    data,
  });
};

export const Type_LaunchFlow = ({ data }: any) => {
  return Type_Node({
    nodeName: "LaunchFlow",
    nodeBusinessName: "Launcher: выход",
    icon: <DownloadIcon />,
    color: colors.launchFlow,
    inputs: 1,
    outputs: 1,
    data,
  });
};

export function Type_Note({ id, data, selected }: any) {
  const { setNodes, getNode } = useReactFlow();

  const node = getNode(id)!;

  const { color, label } = data;

  const setColor = (color: string) =>
    setNodes((nodes) =>
      nodes.map((node) =>
        node.id === id ? { ...node, data: { ...node.data, color } } : node,
      ),
    );

  const setLabel = (label: string) =>
    setNodes((nodes) =>
      nodes.map((node) =>
        node.id === id ? { ...node, data: { ...node.data, label } } : node,
      ),
    );

  return (
    <div className="note-body">
      <NodeResizer minWidth={100} minHeight={50} isVisible={selected} />
      <div
        style={{
          ...basicElementStyle,
          width: node.width!,
          height: node.height!,
          background: color,
          padding: 5,
          justifyContent: "start",
        }}
      >
        <span
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            display: "flex",
            flexDirection: "row",
            gap: "5px",
            alignItems: "start",
          }}
        >
          <div
            style={{
              display: "flex",
              maxWidth: "25px",
              flexDirection: "column",
              gap: "5px",
              alignItems: "center",
            }}
          >
            <ColorPicker
              className="custom-drag-handle"
              size="small"
              value={color}
              onChange={(_, hex) => setColor(hex)}
              styles={{
                popupOverlayInner: {
                  width: 468 + 24,
                },
              }}
              presets={[
                {
                  label: "Recommended",
                  colors: presetColors,
                },
              ]}
              panelRender={(_, { components: { Picker, Presets } }) => (
                <div
                  className="custom-panel"
                  style={{
                    display: "flex",
                    width: 468,
                  }}
                >
                  <div
                    style={{
                      flex: 1,
                    }}
                  >
                    <Presets />
                  </div>
                  <Divider
                    type="vertical"
                    style={{
                      height: "auto",
                    }}
                  />
                  <div
                    style={{
                      width: 234,
                    }}
                  >
                    <Picker />
                  </div>
                </div>
              )}
            />
          </div>
          <Input.TextArea
            value={label}
            onChange={(e) => setLabel(e.target.value)}
            autoSize={{ minRows: 1, maxRows: 25 }}
            variant="borderless"
            style={{ color: getFontColor(color), padding: 0 }}
          />
        </span>
      </div>
    </div>
  );
}

const presetColors = [
  "#000000",
  "#000000E0",
  "#000000A6",
  "#00000073",
  "#00000040",
  "#00000026",
  "#0000001A",
  "#00000012",
  "#0000000A",
  "#00000005",
  "#F5222D",
  "#FA8C16",
  "#FADB14",
  "#8BBB11",
  "#52C41A",
  "#13A8A8",
  "#1677FF",
  "#2F54EB",
  "#722ED1",
  "#EB2F96",
  "#F5222D4D",
  "#FA8C164D",
  "#FADB144D",
  "#8BBB114D",
  "#52C41A4D",
  "#13A8A84D",
  "#1677FF4D",
  "#2F54EB4D",
  "#722ED14D",
  "#EB2F964D",
];

function getFontColor(backgroundColor: string) {
  // Функция для преобразования HEX в RGB
  function hexToRgb(hex: string) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
      hex.length > 7 ? hex.slice(0, -2) : hex,
    );
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
          a: hex.length > 7 ? parseInt(hex.slice(-2), 16) : parseInt("E1", 16),
        }
      : null;
  }

  // Функция для расчета яркости цвета
  function luminance(r: number, g: number, b: number, a: number) {
    var c = [r, g, b].map(function (v) {
      v /= 255;
      return v <= 0.03928
        ? (v / 12.92) * (a / 100)
        : Math.pow((v + 0.055) / 1.055, 2.4) * (a / 100);
    });
    return c[0] * 0.2126 + c[1] * 0.7152 + c[2] * 0.0722;
  }

  // Преобразуем HEX в RGB
  var rgb = hexToRgb(backgroundColor);

  // Рассчитываем яркость цвета
  if (rgb) {
    const brightness = luminance(rgb.r, rgb.g, rgb.b, rgb.a);

    // Determine text color based on brightness and alpha
    if (rgb.a < 50) {
      return "black";
    }

    if (rgb.a > 150) {
      return "white";
    }

    if (brightness > 0.18) {
      if (rgb.a < 50) {
        return "white"; // Semi-transparent background, use white text
      } else {
        return "black"; // Opaque background, use black text
      }
    }
    //  else {
    //   // Check contrast ratio for low-opacity backgrounds (0-5%)
    //   if (rgb.a <= 50) {
    //     if(brightness === 0){
    //       return 'black'
    //     }
    //     const contrastRatio = luminance(255, 255, 255, 1) / brightness;

    //     if (contrastRatio < 4.5) {
    //       return 'black'; // Use black text for better contrast
    //     }
    //   }
    //   return 'white'; // Dark background, use white text
    // }
  }
}
