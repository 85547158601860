import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { Button, Popconfirm, Tag, notification } from "antd";
import type {
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
} from "material-react-table";
import {
  MRT_ColumnDef,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { MRT_Localization_RU } from "material-react-table/locales/ru";
import { useState } from "react";
import { Link } from "react-router-dom";
import AgentService from "../../../entities/model/AgentService";
import FlowService from "../../../entities/model/FlowService";
import TransferService from "../../../entities/model/TransferService";
import IntegrationFlowCopy from "./flowCopy";
import IntegrationFlowLaunch from "./flowLaunch";

type TasksListResponse = {
  code: number;
  data: {
    rows: any[];
    count: number;
  };
};

type AgentsResponse = {
  code: number;
  data: any[];
};

const IntegrationTaskPage = () => {
  const [agent, setAgent] = useState<string | null>(
    localStorage.getItem("agentSelected"),
  );

  const [copyModalState, setCopyModalState] = useState<boolean>(false);
  const [copyFlowTarget, setCopyFlowTarget] = useState<string | null>();
  const [copyFlowName, setCopyFlowName] = useState<string | null>();

  const [launchModalState, setLaunchModalState] = useState<boolean>(false);
  const [launchFlowId, setLaunchFlowId] = useState<string | null>();

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [filters, setFilters] = useState<MRT_ColumnFiltersState>([]);
  const [sorting, setSorting] = useState<MRT_SortingState>([
    { id: "id", desc: false },
  ]);

  const {
    data: { data: { rows, count } } = { data: { rows: [], count: 0 } },
    isError: isDataError,
    isRefetching: isDataRefetching,
    isLoading: isDataLoading,
    refetch: refetchData,
  } = useQuery<TasksListResponse>({
    placeholderData: keepPreviousData,
    queryKey: [
      "integration-tasks",
      filters,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
    ],
    queryFn: async () => {
      const response = await FlowService.getAll({
        page: pagination.pageIndex,
        size: pagination.pageSize,
        filters,
        sorting,
      });

      console.log(response.data);

      if (response.code !== 1) {
        throw new Error(
          `Ошибка при попытке загрузки списка заданий интеграции: "${response.text}"`,
        );
      }

      return response;
    },
  });

  // TODO: handle error
  const {
    data: { data: agents } = { data: [] },
    isError: isAgentsError,
    isRefetching: isAgentsRefetching,
    isLoading: isAgentsLoading,
    refetch: refetchAgents,
  } = useQuery<AgentsResponse>({
    placeholderData: keepPreviousData,
    queryKey: ["integration-agents"],
    queryFn: async () => {
      const response = await AgentService.getAll();

      if (response.code !== 1) {
        throw new Error(
          `Ошибка при попытке загрузки списка агентов интеграции: "${response.text}"`,
        );
      }
      return response;
    },
  });

  const refresh = () => {
    refetchData();
    refetchAgents();
  };

  const openCopyDrawer = (id: string) => {
    setCopyFlowTarget(id);
    setCopyModalState(true);
  };

  const openLaunchDrawer = (id: string) => {
    setLaunchFlowId(id);
    setLaunchModalState(true);
  };

  const closeCopyDrawer = () => {
    setCopyFlowTarget("");
    setCopyFlowName("");
    setCopyModalState(false);
    refetchData();
  };

  const closeLaunchDrawer = () => {
    setLaunchFlowId("");
    setLaunchModalState(false);
    refetchData();
  };

  const launchIntegration = async (id: string) => {
    if (!agent) {
      return notification.error({ message: "Не выбран агент" });
    }

    if (!id) {
      return notification.error({ message: "Ошибка при запуске потока" });
    }

    const response = await TransferService.launchFlow(agent, id);
    if (response.code !== 1) {
      console.log(response);
      notification.error({ message: "Ошибка при выполнении задания" });
      return;
    }

    notification.success({ message: `Поток поставлен в очередь` });
  };

  const launchCopy = async () => {
    if (!(copyFlowName && copyFlowName.length > 0 && copyFlowTarget)) {
      notification.error({ message: "Введите наименование потока" });
      return;
    }

    const response = await FlowService.copy(copyFlowTarget, copyFlowName);

    if (response.code !== 1) {
      notification.error({ message: "Ошибка при попытке копирования" });
      console.log(response);
      return;
    }

    notification.success({ message: `Успешно скопировано` });
    closeCopyDrawer();
  };

  const launchDelete = async (id: string) => {
    const response = await FlowService.delete(id);

    if (response.code !== 1) {
      notification.error({
        message: "Возникла ошибка при потока",
        description: `Код ответа: ${response.code}`,
      });
      return;
    }

    notification.warning({ message: `Поток ${id} успешно удален` });
    refetchData();
  };

  const columns: MRT_ColumnDef<any>[] = [
    {
      header: "Идентификатор",
      accessorKey: "id",
      enableClickToCopy: true,
    },
    {
      header: "Проект",
      accessorKey: "project",
      Cell: ({ row }) =>
        row.original.project && (
          <Tag
            style={{
              fontWeight: "bold",
              fontSize: 16,
              padding: "5px 10px 5px 10px",
            }}
          >
            {row.original.project}
          </Tag>
        ),
    },

    {
      header: "Действие",
      enableGrouping: false,
      Cell: ({ row }) => (
        <div style={{ display: "flex", flexDirection: "row", gap: "15px" }}>
          <Button
            size="small"
            onClick={() => openLaunchDrawer(row.original.id)}
          >
            ▶
          </Button>
          <Link to={`/integration/constructor/${row.original.id}`}>
            <Button size="small">✏</Button>
          </Link>
          <Button size="small" onClick={() => openCopyDrawer(row.original.id)}>
            🖨️
          </Button>
          <Popconfirm
            title={`Вы действительно хотите удалить формат файла ${row.original.id}?`}
            okText="Да"
            cancelText="Нет"
            onConfirm={() => launchDelete(row.original.id)}
          >
            <Button size="small">❌</Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  const table = useMaterialReactTable({
    data: rows,
    columns: columns,
    initialState: { density: "compact" },
    localization: MRT_Localization_RU,

    enableColumnResizing: false,
    enableStickyHeader: true,
    enableGlobalFilter: false,

    enableGrouping: true,
    groupedColumnMode: "remove",

    layoutMode: "grid",

    muiToolbarAlertBannerProps: isDataError
      ? { color: "error", children: "Возникла ошибка при загрузке данных" }
      : undefined,

    muiTableBodyRowProps: () => ({
      sx: {
        height: "50px",
      },
    }),

    manualFiltering: true,
    onColumnFiltersChange: setFilters,

    manualPagination: true,
    onPaginationChange: setPagination,

    manualSorting: true,
    onSortingChange: setSorting,

    rowCount: count,

    state: {
      columnFilters: filters,
      isLoading: isDataLoading,
      pagination,
      showAlertBanner: isDataError,
      showProgressBars: isDataRefetching,
      sorting,
    },
  });

  const handleAgentSelect = (agent: string) => {
    setAgent(agent);
    localStorage.setItem("agentSelected", agent);
  };

  return (
    <div>
      <div
        className="ManageTable"
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "15px",
          alignItems: "center",
        }}
      >
        <Link to={`/integration/constructor/new`}>
          <Button size="small">➕</Button>
        </Link>
        <Button size="small" onClick={refresh}>
          🔄
        </Button>
      </div>
      <div style={{ marginTop: "15px" }}>
        <MaterialReactTable table={table} />
      </div>
      <IntegrationFlowCopy
        open={copyModalState}
        onClose={closeCopyDrawer}
        flowToCopyName={copyFlowTarget}
        launchCopy={launchCopy}
        setNewFlowName={setCopyFlowName}
      />
      <IntegrationFlowLaunch
        open={launchModalState}
        onClose={closeLaunchDrawer}
        flowId={launchFlowId}
      />
    </div>
  );
};

export default IntegrationTaskPage;
