import { Input, Tabs, notification } from "antd";
import React, { useState } from "react";
import TransferService from "../../../entities/model/TransferService";
import Button from "../../../shared/components/button";

const CreateSourcePage: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState("ks");

  return (
    <Tabs
      tabPosition={"left"}
      onChange={(e) => setSelectedTab(e)}
      items={systems}
    />
  );
};

export default CreateSourcePage;

const KnowledgeSpace: React.FC = () => {
  const [id, setId] = useState<string>("");
  const [url, setUrl] = useState<string>("");
  const [login, setLogin] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const onCreate = async () => {
    if (id === "") {
      return notification.error({
        message: "Введите идентификатор подключения",
      });
    }
    if (url === "") {
      return notification.error({ message: "Введите URL" });
    }
    if (login === "") {
      return notification.error({ message: "Введите логин" });
    }
    if (password === "") {
      return notification.error({ message: "Введите пароль" });
    }

    const response = await TransferService.testConnectionKS(
      url,
      login,
      password,
    );

    if (response.code === 1) {
      const response = await TransferService.create(id, "Knowledge Space", {
        url,
        login,
        password,
      });
      notification.info({
        message: response.text,
        description: `Код ответа: ${response.code}`,
      });
    } else {
      notification.info({
        message: response.text,
        description: `Код ответа: ${response.code}`,
      });
    }
  };

  const onTest = async () => {
    if (id === "") {
      return notification.error({
        message: "Введите идентификатор подключения",
      });
    }
    if (url === "") {
      return notification.error({ message: "Введите URL" });
    }
    if (login === "") {
      return notification.error({ message: "Введите логин" });
    }
    if (password === "") {
      return notification.error({ message: "Введите пароль" });
    }

    const response = await TransferService.testConnectionKS(
      url,
      login,
      password,
    );

    notification.info({
      message: response.text,
      description: `Код ответа: ${response.code}`,
    });
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "15px",
          marginBottom: "15px",
        }}
      >
        <span>Идентификатор подключения</span>
        <Input
          placeholder="Введите идентификатор"
          onChange={(el) => setId(el.target.value)}
        />
        <span>URL системы</span>
        <Input
          placeholder="Введите URL"
          onChange={(el) => setUrl(el.target.value)}
        />
        <span>Логин пользователя</span>
        <Input
          placeholder="Введите логин"
          onChange={(el) => setLogin(el.target.value)}
        />
        <span>Пароль пользователя</span>
        <Input.Password
          placeholder="Введите пароль"
          onChange={(el) => setPassword(el.target.value)}
        />
      </div>
      <div style={{ display: "flex", flexDirection: "row", gap: "15px" }}>
        <Button type="primary" label="Создать" onClick={onCreate} />
        <Button type="dashed" label="Проверить подключение" onClick={onTest} />
      </div>
    </>
  );
};

const Postgresql: React.FC = () => {
  const [id, setId] = useState<string>("");
  const [host, setHost] = useState<string>("");
  const [db, setDb] = useState<string>("");
  const [user, setUser] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const onCreate = async () => {
    if (id === "") {
      return notification.error({
        message: "Введите идентификатор подключения",
      });
    }
    if (host === "") {
      return notification.error({ message: "Введите хост" });
    }
    if (db === "") {
      return notification.error({
        message: "Введите наименование базы данных",
      });
    }
    if (user === "") {
      return notification.error({ message: "Введите логин" });
    }
    if (password === "") {
      return notification.error({ message: "Введите пароль" });
    }

    const response = await TransferService.createConnectionPG(
      id,
      host,
      db,
      user,
      password,
    );

    if (response.code === 1) {
      notification.success({
        message: response.text,
        description: `Код ответа: ${response.code}`,
      });
    } else {
      notification.error({
        message: response.text,
        description: `Код ответа: ${response.code}`,
      });
    }
  };

  const onTest = async () => {
    if (id === "") {
      return notification.error({
        message: "Введите идентификатор подключения",
      });
    }
    if (host === "") {
      return notification.error({ message: "Введите хост" });
    }
    if (db === "") {
      return notification.error({
        message: "Введите наименование базы данных",
      });
    }
    if (user === "") {
      return notification.error({ message: "Введите логин" });
    }
    if (password === "") {
      return notification.error({ message: "Введите пароль" });
    }

    const response = await TransferService.testConnectionPG(
      host,
      db,
      user,
      password,
    );

    notification.info({
      message: response.text,
      description: `Код ответа: ${response.code}`,
    });
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "15px",
          marginBottom: "15px",
        }}
      >
        <span>Идентификатор</span>
        <Input
          placeholder="Введите идентификатор"
          onChange={(el) => setId(el.target.value)}
        />
        <span>Хост:порт</span>
        <Input
          placeholder="Введите идентификатор"
          onChange={(el) => setHost(el.target.value)}
        />
        <span>База данных</span>
        <Input
          placeholder="Введите наименование БД"
          onChange={(el) => setDb(el.target.value)}
        />
        <span>Пользователь</span>
        <Input
          placeholder="Введите логин"
          onChange={(el) => setUser(el.target.value)}
        />
        <span>Пароль пользователя</span>
        <Input.Password
          placeholder="Введите пароль"
          onChange={(el) => setPassword(el.target.value)}
        />
      </div>
      <div style={{ display: "flex", flexDirection: "row", gap: "15px" }}>
        <Button type="primary" label="Создать" onClick={onCreate} />
        <Button type="dashed" label="Проверить подключение" onClick={onTest} />
      </div>
    </>
  );
};

const Clickhouse: React.FC = () => {
  const [id, setId] = useState<string>("");
  const [host, setHost] = useState<string>("");
  const [db, setDb] = useState<string>("");
  const [user, setUser] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const onCreate = async () => {
    if (id === "") {
      return notification.error({
        message: "Введите идентификатор подключения",
      });
    }
    if (host === "") {
      return notification.error({ message: "Введите хост" });
    }
    if (db === "") {
      return notification.error({
        message: "Введите наименование базы данных",
      });
    }
    if (user === "") {
      return notification.error({ message: "Введите логин" });
    }
    if (password === "") {
      return notification.error({ message: "Введите пароль" });
    }

    const response = await TransferService.createConnectionClickhouse(
      id,
      host,
      db,
      user,
      password,
    );

    if (response.code === 1) {
      notification.success({
        message: response.text,
        description: `Код ответа: ${response.code}`,
      });
    } else {
      notification.error({
        message: response.text,
        description: `Код ответа: ${response.code}`,
      });
    }
  };

  const onTest = async () => {
    if (id === "") {
      return notification.error({
        message: "Введите идентификатор подключения",
      });
    }
    if (host === "") {
      return notification.error({ message: "Введите хост" });
    }
    if (db === "") {
      return notification.error({
        message: "Введите наименование базы данных",
      });
    }
    if (user === "") {
      return notification.error({ message: "Введите логин" });
    }
    if (password === "") {
      return notification.error({ message: "Введите пароль" });
    }

    const response = await TransferService.testConnectionClickhouse(
      host,
      db,
      user,
      password,
    );

    notification.info({
      message: response.text,
      description: `Код ответа: ${response.code}`,
    });
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "15px",
          marginBottom: "15px",
        }}
      >
        <span>Идентификатор</span>
        <Input
          placeholder="Введите идентификатор"
          onChange={(el) => setId(el.target.value)}
        />
        <span>Хост:порт</span>
        <Input
          placeholder="Введите идентификатор"
          onChange={(el) => setHost(el.target.value)}
        />
        <span>База данных</span>
        <Input
          placeholder="Введите наименование БД"
          onChange={(el) => setDb(el.target.value)}
        />
        <span>Пользователь</span>
        <Input
          placeholder="Введите логин"
          onChange={(el) => setUser(el.target.value)}
        />
        <span>Пароль пользователя</span>
        <Input.Password
          placeholder="Введите пароль"
          onChange={(el) => setPassword(el.target.value)}
        />
      </div>
      <div style={{ display: "flex", flexDirection: "row", gap: "15px" }}>
        <Button type="primary" label="Создать" onClick={onCreate} />
        <Button type="dashed" label="Проверить подключение" onClick={onTest} />
      </div>
    </>
  );
};

const RabbitMQ: React.FC = () => {
  const [id, setId] = useState<string>("");
  const [host, setHost] = useState<string>("");
  const [queue, setQueue] = useState<string>("");
  const [user, setUser] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const onCreate = async () => {
    if (id === "") {
      return notification.error({
        message: "Введите идентификатор подключения",
      });
    }
    if (host === "") {
      return notification.error({ message: "Введите хост" });
    }
    if (queue === "") {
      return notification.error({ message: "Введите наименование очереди" });
    }
    if (user === "") {
      return notification.error({ message: "Введите логин" });
    }
    if (password === "") {
      return notification.error({ message: "Введите пароль" });
    }

    const response = await TransferService.createConnectionRabbitMQ(
      id,
      host,
      queue,
      user,
      password,
    );

    if (response.code === 1) {
      notification.success({
        message: response.text,
        description: `Код ответа: ${response.code}`,
      });
    } else {
      notification.error({
        message: response.text,
        description: `Код ответа: ${response.code}`,
      });
    }
  };

  const onTest = async () => {
    if (id === "") {
      return notification.error({
        message: "Введите идентификатор подключения",
      });
    }
    if (host === "") {
      return notification.error({ message: "Введите хост" });
    }
    if (queue === "") {
      return notification.error({ message: "Введите наименование очереди" });
    }
    if (user === "") {
      return notification.error({ message: "Введите логин" });
    }
    if (password === "") {
      return notification.error({ message: "Введите пароль" });
    }

    const response = await TransferService.testConnectionRabbitMQ(
      host,
      queue,
      user,
      password,
    );

    notification.info({
      message: response.text,
      description: `Код ответа: ${response.code}`,
    });
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "15px",
          marginBottom: "15px",
        }}
      >
        <span>Идентификатор</span>
        <Input
          placeholder="Введите идентификатор"
          onChange={(el) => setId(el.target.value)}
        />
        <span>Хост:порт</span>
        <Input
          placeholder="Введите идентификатор"
          onChange={(el) => setHost(el.target.value)}
        />
        <span>Наименование очереди</span>
        <Input
          placeholder="Введите наименование очереди"
          onChange={(el) => setQueue(el.target.value)}
        />
        <span>Пользователь</span>
        <Input
          placeholder="Введите логин"
          onChange={(el) => setUser(el.target.value)}
        />
        <span>Пароль пользователя</span>
        <Input.Password
          placeholder="Введите пароль"
          onChange={(el) => setPassword(el.target.value)}
        />
      </div>
      <div style={{ display: "flex", flexDirection: "row", gap: "15px" }}>
        <Button type="primary" label="Создать" onClick={onCreate} />
        <Button type="dashed" label="Проверить подключение" onClick={onTest} />
      </div>
    </>
  );
};

const systems = [
  {
    label: "Knowledge Space",
    key: "ks",
    children: <KnowledgeSpace />,
  },
  {
    label: "Postgresql",
    key: "postgres",
    children: <Postgresql />,
  },
  {
    label: "Clickhouse",
    key: "clickhouse",
    children: <Clickhouse />,
  },
  {
    label: "RabbitMQ",
    key: "rabbitmq",
    children: <RabbitMQ />,
  },
];
