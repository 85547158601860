import { Edge, Node } from "@xyflow/react";
import { message } from "antd";
import { produce } from "immer";
import { SetStateAction } from "react";

class IntegrationManager {
  static initial(
    id: string,
    nodes: Node[],
    edges: Edge[],
    setSourceFields: SetStateAction<any>,
    setTargetFields: SetStateAction<any>,
  ) {
    const node: any = nodes.find((node) => node.id === id)!;

    setTargetFields(node.data.formula ?? []);

    const edge = edges.find((edge) => edge.target === id);

    if (!edge) {
      return message.warning(
        `Обратите внимание, что в эту ноду (${id}) не входит поток данных`,
      );
    }

    const sourceNode = nodes.find((node) => node.id === edge.source);

    if (!sourceNode?.data.formula) {
      return message.warning(
        `Обратите внимание, что в ноде ${edge.source} не заданы поля`,
      );
    }

    setSourceFields(sourceNode?.data.formula ?? []);
  }

  static changeNodeId(
    id: string,
    nodeName: string,
    nodes: Node[],
    setNodeId: any,
    setNodes: any,
    setEdges: any,
    setEditNodeNameStatus: any,
  ) {
    if (id === nodeName) {
      return;
    }

    if (nodes.find((node) => node.id === nodeName)) {
      message.error("Уже существует нода с таким именем");
      return;
    }

    setNodes(
      produce((nodes: Node[]) =>
        nodes.forEach((node) => {
          if (node.id === id) {
            node.id = nodeName;
            node.data.label = nodeName;
          }

          if (node.type === "join" && node.data.mainTable === id) {
            node.data.mainTable = nodeName;
          }

          if (node.type === "join" && node.data.secondaryTable === id) {
            node.data.secondaryTable = nodeName;
          }

          ((node.data.formula || []) as any[]).forEach((formula: any) => {
            if (formula.formula) {
              formula.formula = formula.formula.replaceAll(id, nodeName);
            }

            if (formula.filter) {
              formula.filter = formula.filter.replaceAll(id, nodeName);
            }
          });
        }),
      ),
    );

    setEdges(
      produce((edges: Edge[]) =>
        edges.forEach((edge) => {
          if (edge.source === id) {
            edge.source = nodeName;
          }

          if (edge.target === id) {
            edge.target = nodeName;
          }
        }),
      ),
    );

    setNodeId(nodeName);
    setEditNodeNameStatus(false);

    message.success(`Успешно переименовано с ${id} на ${nodeName}`);
  }
}

export default IntegrationManager;
